<template>
    <div
        class="nibnut-multiselect text-left"
        @click="maybe_toggle"
    >
        <div v-if="canToggleAll" class="nibnut-proxied-control">
            <input
                ref="field"
                type="text"
                role="listbox"
                readonly
                @focus="focus"
                @blur="blur"
                @keydown="keydown"
                aria-haspopup="listbox"
                :aria-expanded="picking"
            />
        </div>
        <div
            :class="{ focused: focused || picking }"
            class="form-input"
        >
            <label>
                <slot
                    name="value"
                    :value="value"
                    :placeholder="placeholder"
                >
                    <template v-if="(display === 'normal') || !value || !value.length">
                        {{ label || placeholder}}
                    </template>
                    <template v-else-if="display === 'chips'">
                        <span
                            v-for="selection of chips"
                            :key="selection"
                            class="chip"
                        >
                            {{ option_by_id(selection).name }}
                            <a
                                v-if="!disabled"
                                href="#"
                                class="btn btn-clear"
                                aria-label="Close"
                                role="button"
                                @click.prevent.stop="option_toggle_selected(option_by_id(selection))"
                            ></a>
                        </span>
                        <template v-if="compactSelection && !!value && !!value.length">
                            <span
                                class="chip"
                            >
                                {{ compactSelection }}
                            </span>
                        </template>
                        <template v-else-if="!value || !value.length">{{ placeholder }}</template>
                    </template>
                </slot>
            </label>
            <div
                class="nibnut-multiselect-toggle"
            >
                <slot name="indicator">
                    <open-icon glyph="ellipsis-v" />
                </slot>
            </div>
        </div>
        <div
            ref="overlay"
            :class="{ active: picking }"
            class="popover hover-disabled"
        >
            <div class="popover-container">
                <div class="card">
                    <div class="card-header">
                        <div class="tile tile-centered">
                            <div v-if="(canToggleAll && !maxSelection)" class="tile-icon">
                                <default-toggle-input
                                    id="nibnut-multiselect-select-all"
                                    type="checkbox"
                                    name="select_all"
                                    :value="all_selected"
                                    @input="toggle_all_selected"
                                >
                                    <span v-if="all_selected">{{ translate("Unselect All") }}</span>
                                    <span v-else>{{ translate("Select All") }}</span>
                                </default-toggle-input>
                            </div>
                            <div class="tile-content">
                                <input
                                    v-if="!canToggleAll"
                                    ref="field"
                                    type="text"
                                    v-model="type_ahead"
                                    aria-haspopup="listbox"
                                    :aria-expanded="picking"
                                    class="form-input"
                                    @focus="focus"
                                    @blur="blur"
                                    @keydown="keydown"
                                />
                            </div>
                            <div class="tile-action">
                                <default-button
                                    flavor="link"
                                    @click.prevent.stop="hide"
                                >
                                    {{ translate("Close") }}
                                </default-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" :style="{'max-height': scrollHeight}">
                        <div class="divider"></div>
                        <ul ref="options" role="listbox" aria-multiselectable="true" class="menu">
                            <li
                                v-for="option in visible_options"
                                :key="option.id"
                                :id="`nibnut-multiselect-option-${option.id}`"
                                role="option"
                                :aria-selected="option_is_selected(option)"
                                :aria-label="option.name"
                                tabindex="0"
                                :class="{ active: option_is_highlighted(option) }"
                                @keydown="options_keydown($event, option)"
                            >
                                <div class="d-flex">
                                    <default-toggle-input
                                        type="checkbox"
                                        name="select"
                                        :value="option_is_selected(option)"
                                        tabindex="0"
                                        :indeterminate="option_is_partially_selected(option)"
                                        :disabled="!toggable_option(option)"
                                        class="flex-variable"
                                        @input="option_toggle_selected(option)"
                                        @keydown="options_keydown($event, option)"
                                    >
                                        {{ option.name }}
                                    </default-toggle-input>
                                    <default-button
                                        v-if="!!option.sub_levels"
                                        flavor="link"
                                        color="light"
                                        class="flex-static"
                                        @click.prevent.stop="option_toggle_expansion(option)"
                                    >
                                        <open-icon
                                            :glyph="option_is_expanded(option) ? 'angle-up' : 'angle-down'"
                                        />
                                    </default-button>
                                </div>
                                <ul
                                    v-if="!!option.sub_levels && option_is_expanded(option)"
                                    role="listbox"
                                    aria-multiselectable="true"
                                    class="menu"
                                >
                                    <li
                                        v-for="sub_option in option.sub_levels"
                                        :key="sub_option.id"
                                        :id="`nibnut-multiselect-option-${sub_option.id}`"
                                        role="option"
                                        :aria-selected="option_is_selected(sub_option)"
                                        :aria-label="sub_option.name"
                                        tabindex="0"
                                        :class="{ active: option_is_highlighted(sub_option) }"
                                        @keydown="options_keydown($event, sub_option)"
                                    >
                                        <default-toggle-input
                                            type="checkbox"
                                            name="select"
                                            :value="option_is_selected(sub_option)"
                                            tabindex="0"
                                            :disabled="!toggable_option(sub_option)"
                                            @input="option_toggle_selected(sub_option)"
                                            @keydown="options_keydown($event, sub_option)"
                                        >
                                            {{ sub_option.name }}
                                        </default-toggle-input>
                                    </li>
                                </ul>
                            </li>
                            <li
                                v-if="(!visible_options || (visible_options && visible_options.length === 0))"
                                class="nibnut-multiselect-empty"
                            >
                                <slot name="empty">
                                    ({{ translate("No options") }})
                                </slot>
                            </li>
                        </ul>
                    </div>
                    <div v-if="canToggleAll && type_ahead" class="card-footer text-small text-gray">
                        {{ type_ahead }}
                        <default-button
                            flavor="link"
                            size="sm"
                            class="float-right"
                            @click.prevent.stop="reset_typeahead"
                        >
                            <open-icon glyph="backspace" />
                        </default-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DefaultToggleInput from "@/nibnut/components/Inputs/DefaultToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

let outside_click_listener
// let scroll_listener
let resize_listener

export default {
    name: "MultiSelect",
    components: {
        DefaultToggleInput,
        DefaultButton,
        OpenIcon
    },
    watch: {
        highlighted_option: "focus_dom_node"
    },
    beforeDestroy () {
        this.unbind_listeners()
    },
    methods: {
        maybe_toggle (event) {
            if(!this.$refs.overlay || !this.$refs.overlay.contains(event.target)) {
                if(this.picking) this.hide()
                else {
                    this.$refs.field.focus()
                    this.show()
                }
            }
        },
        option_dom_node (option_id) {
            return this.$el.querySelector(`#nibnut-multiselect-option-${option_id}`)
        },
        dom_node_option (node) {
            if(node && node.id) {
                const matches = node.id.match(/^nibnut-multiselect-option-(.+?)$/)
                if(matches) return this.option_by_id(matches[1])
            }
            return null
        },
        focus_dom_node () {
            if(this.highlighted_option) {
                const node = this.option_dom_node(this.highlighted_option.id)
                if(node) {
                    node.querySelector("input[type='checkbox']")
                    if(node) node.focus()
                }
            }
        },
        focus () {
            this.focused = true
        },
        blur () {
            this.focused = false
        },
        show () {
            this.highlighted_option = null
            this.type_ahead = ""
            this.picking = true
            this.$nextTick(() => {
                this.bind_listeners()
                if(!!this.value && !!this.value.length) {
                    const option = this.option_by_id(this.value[0])
                    if(option) {
                        const node = document.getElementById(`nibnut-multiselect-option-${option.id}`)
                        if(node) {
                            this.highlighted_option = option
                            this.$refs.options.parentNode.scrollTop = node.offsetTop
                        }
                    }
                }
            })
        },
        hide () {
            this.$nextTick(() => {
                this.unbind_listeners()
            })
            this.type_ahead = ""
            this.picking = false
        },
        typeahead_keydown (event) {
            switch (event.which) {
            // backspace
            case 8:
                if(this.canToggleAll) this.type_ahead = ""
                else {
                    const selection = window.getSelection().toString()
                    const start = selection ? this.type_ahead.indexOf(selection) : 0
                    const length = selection ? selection.length : -1
                    this.type_ahead = this.type_ahead.slice(start, length)
                }
                break
            default:
                if(!!event.code && !!event.code.match(/^key/i)) {
                    if(!this.picking) this.show()
                    this.type_ahead += event.key.toLowerCase()
                    event.preventDefault()
                    event.stopPropagation()
                    const option = this.visible_options.find(option => {
                        return option.name.toLowerCase().indexOf(this.type_ahead) === 0
                    })
                    if(option) {
                        const node = document.getElementById(`nibnut-multiselect-option-${option.id}`)
                        if(node) {
                            this.highlighted_option = option
                            this.$refs.options.parentNode.scrollTop = node.offsetTop
                        }
                    }
                }
                break
            }
        },
        reset_typeahead (event) {
            if(!this.canToggleAll) return
            this.type_ahead = ""
            if(event && event.target && event.target.closest(".card-footer")) {
                setTimeout(() => {
                    this.$refs.field.focus()
                }, 150)
            }
        },
        keydown (event) {
            switch (event.which) {
            // down
            case 40:
                if(this.visible_options && !this.picking) this.show()
                else if(this.picking) {
                    this.type_ahead = ""
                    this.highlighted_option = this.visible_options[0]
                }
                break
            // up
            case 38:
                if(this.picking) {
                    this.type_ahead = ""
                    this.highlighted_option = this.visible_options[this.visible_options.length - 1]
                }
                break
            // enter and space
            case 32:
            case 13:
                if(this.picking) {
                    if(this.highlighted_option) this.option_toggle_selected(this.highlighted_option)
                    event.preventDefault()
                    event.stopPropagation()
                    this.hide()
                }
                break
            // escape
            case 27:
                if(this.picking) {
                    this.hide()
                    event.preventDefault()
                }
                break
            // tab
            case 9:
                this.hide()
                break
            default:
                this.typeahead_keydown(event)
                break
            }
        },
        option_by_id (id) {
            let found_option = null
            if(this.options) {
                found_option = this.options.find(option => option.id === id)
                if(!found_option) {
                    this.options.forEach(option => {
                        if(!found_option && !!option.sub_levels) {
                            const sub_option = option.sub_levels.find(option => option.id === id)
                            if(sub_option) found_option = sub_option
                        }
                    })
                }
            }
            return found_option || { id, name: id }
        },
        option_is_selected (option) {
            if(!this.value) return false
            let selected = !!this.value.find(value => option.id === value)
            if(!selected && option.sub_levels && (typeof option.id === "string")) selected = this.option_is_fully_selected(option)
            if(!selected && !!option.parent_id) selected = this.option_is_selected(this.option_by_id(option.parent_id))
            return selected
        },
        option_is_fully_selected (option) {
            if(!this.value || !option) return false
            if(this.value.indexOf(option.id) >= 0) return true

            return option.sub_levels.length === option.sub_levels.filter(suboption => {
                return this.value.indexOf(suboption.id) >= 0
            }).length
        },
        option_is_partially_selected (option) {
            if(!this.value || !option.sub_levels || !option.sub_levels.length) return false
            return !!option.sub_levels.find(suboption => {
                return this.value.indexOf(suboption.id) < 0
            }) && !!option.sub_levels.find(suboption => {
                return this.value.indexOf(suboption.id) >= 0
            })
        },
        option_is_highlighted (option) {
            if(this.highlighted_option) return (this.highlighted_option === option)
            return false
        },
        options_keydown (event, option) {
            let node = this.highlighted_option ? this.option_dom_node(this.highlighted_option.id) : null
            switch (event.which) {
            // down
            case 40:
                this.type_ahead = ""
                if(!node) this.highlighted_option = this.visible_options[0]
                else if(this.highlighted_option.sub_levels && this.highlighted_option.sub_levels.length) this.highlighted_option = this.highlighted_option.sub_levels[0]
                else if(node.nextElementSibling) this.highlighted_option = this.dom_node_option(node.nextElementSibling)
                else if(option.parent_id) {
                    node = this.option_dom_node(this.option_by_id(option.parent_id).id)
                    if(node.nextElementSibling) this.highlighted_option = this.dom_node_option(node.nextElementSibling)
                    else this.highlighted_option = this.visible_options[0]
                } else this.highlighted_option = this.visible_options[0]
                event.preventDefault()
                event.stopPropagation()
                break

            // up
            case 38:
                this.type_ahead = ""
                if(!node) {
                    this.highlighted_option = this.visible_options[this.visible_options.length - 1]
                    if(this.highlighted_option.sub_levels && this.highlighted_option.sub_levels.length) this.highlighted_option = this.highlighted_option.sub_levels[this.highlighted_option.sub_levels.length - 1]
                } else if(node.previousElementSibling) {
                    this.highlighted_option = this.dom_node_option(node.previousElementSibling)
                    if(this.highlighted_option.sub_levels && this.highlighted_option.sub_levels.length) this.highlighted_option = this.highlighted_option.sub_levels[this.highlighted_option.sub_levels.length - 1]
                } else if(option.parent_id) {
                    this.highlighted_option = this.option_by_id(option.parent_id)
                } else {
                    this.highlighted_option = this.visible_options[this.visible_options.length - 1]
                    if(this.highlighted_option.sub_levels && this.highlighted_option.sub_levels.length) this.highlighted_option = this.highlighted_option.sub_levels[this.highlighted_option.sub_levels.length - 1]
                }
                event.preventDefault()
                event.stopPropagation()
                break

            // enter or space
            case 13:
            case 32:
                this.option_toggle_selected(option)
                event.preventDefault()
                event.stopPropagation()
                break
            default:
                this.typeahead_keydown(event)
                break
            }
        },
        emit_new_selection (selection, option) {
            if(this.maxSelection) {
                while(selection.length && (selection.length > this.maxSelection)) selection.shift()
            }
            if(this.closeOnToggle) this.hide()
            else this.type_ahead = ""
            this.$emit("input", selection, option)
        },
        toggable_option (option) {
            const selected = this.option_is_selected(option)
            const flattened_selection = this.flattened_selection
            if(this.max_reached && !selected) return false
            if(selected && !!this.minSelection && (flattened_selection.length <= this.minSelection)) return false
            return true
        },
        option_toggle_selected (option, emit = true) {
            if(!this.toggable_option(option)) return
            const selection = this.value ? [...this.value] : []
            let selected_index = selection.indexOf(option.id)
            if(selected_index >= 0) { // deselect item directly
                selection.splice(selected_index, 1) // always remove deselectd option ()be it a child or a parent)
                if(option.sub_levels) { // deselect any child id from selection
                    option.sub_levels.forEach(suboption => {
                        selected_index = selection.indexOf(suboption.id)
                        if(selected_index >= 0) selection.splice(selected_index, 1)
                    })
                }
            } else {
                if(option.parent_id) {
                    selected_index = selection.indexOf(option.parent_id)
                    if(selected_index >= 0) { // parent selected, but we clicked a child: deselect child
                        selection.splice(selected_index, 1) // remove parent
                        const parent = this.option_by_id(option.parent_id)
                        if(parent && parent.sub_levels) {
                            parent.sub_levels.forEach(suboption => { // add all children EXCEPT the child we just deselected
                                if(suboption.id !== option.id) selection.push(suboption.id)
                            })
                        }
                    } else { // parent not selected and we clicked child: add child
                        selection.push(option.id)
                    }
                } else { // selected parent element
                    if(typeof option.id === "string") {
                        if(option.sub_levels) { // toggle any child id from selection (partially_selected => select all, none selected => select all, all selected => deselect all)
                            const is_selected = !this.option_is_fully_selected(option)
                            option.sub_levels.forEach(suboption => {
                                if(suboption.sub_levels) { // deselect any child id from selection
                                    suboption.sub_levels.forEach(subsuboption => {
                                        selected_index = selection.indexOf(subsuboption.id)
                                        if(selected_index >= 0) selection.splice(selected_index, 1)
                                    })
                                }
                                selected_index = selection.indexOf(suboption.id)
                                if(is_selected) {
                                    if(selected_index < 0) selection.push(suboption.id)
                                } else {
                                    if(selected_index >= 0) selection.splice(selected_index, 1)
                                }
                            })
                        }
                    } else {
                        selection.push(option.id)
                        if(option.sub_levels) { // deselect any child id from selection
                            option.sub_levels.forEach(suboption => {
                                selected_index = selection.indexOf(suboption.id)
                                if(selected_index >= 0) selection.splice(selected_index, 1)
                            })
                        }
                    }
                }
            }
            if(emit) this.emit_new_selection(selection, option)
            else return selection
        },
        option_is_expanded (option) {
            if((this.expanded[option.id] !== undefined) && (this.expanded[option.id] !== null)) return this.expanded[option.id]
            return this.option_is_selected(option) || this.option_is_partially_selected(option)
        },
        option_toggle_expansion (option) {
            if(this.option_is_expanded(option)) this.$set(this.expanded, option.id, false)
            else this.$set(this.expanded, option.id, true)
        },
        toggle_all_selected () {
            if(this.all_selected) this.emit_new_selection([])
            else {
                let selection = []
                this.options.forEach(option => {
                    selection = [
                        ...selection,
                        ...this.option_toggle_selected(option, false)
                    ]
                })
                this.emit_new_selection(selection)
            }
        },
        bind_listeners () {
            if(!outside_click_listener) {
                outside_click_listener = event => {
                    if(this.picking && this.is_outside_click(event)) this.hide()
                }
                document.addEventListener("click", outside_click_listener)
            }
            /*
            if(!scroll_listener) {
                scroll_listener = () => {
                    if(this.picking) this.hide()
                }
                window.addEventListener("scroll", scroll_listener, { passive: true })
            }
            */
            if(!resize_listener) {
                resize_listener = () => {
                    if(this.picking && !navigator.userAgent.match(/(android)/i)) this.hide()
                }
                window.addEventListener("resize", resize_listener)
            }
        },
        unbind_listeners () {
            if(outside_click_listener) {
                document.removeEventListener("click", outside_click_listener)
                outside_click_listener = null
            }
            /*
            if(scroll_listener) {
                window.removeEventListener("scroll", scroll_listener)
                scroll_listener = null
            }
            */
            if(resize_listener) {
                window.removeEventListener("resize", resize_listener)
                resize_listener = null
            }
        },
        is_outside_click (event) {
            return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target) || (this.$refs.overlay && this.$refs.overlay.contains(event.target)))
        }
    },
    computed: {
        visible_options () {
            return this.options
        },
        flattened_selection () {
            if(!this.value || !this.options) return []

            let selection = []
            this.value.forEach(option_id => {
                if(typeof option_id === "number") selection.push(option_id)
                const option = this.option_by_id(option_id)
                if(!!option.sub_levels && !!option.sub_levels.length) selection = selection.concat(option.sub_levels)
            })
            return selection
        },
        chips () {
            if(!this.value || !this.options || this.compactSelection) return []
            // group children into parent if all selected
            const chips = []
            this.value.forEach(option_id => {
                const parent_option = this.options.find(option => {
                    if(option.id === option_id) return true
                    return !!option.sub_levels && !!option.sub_levels.find(sub_option => sub_option.id === option_id)
                })
                if(parent_option && (typeof parent_option.id !== "string") && this.option_is_fully_selected(parent_option)) {
                    if(chips.indexOf(parent_option.id) < 0) chips.push(parent_option.id)
                } else chips.push(option_id)
            })
            return chips
        },
        all_selected () {
            if(!this.value || !this.value.length) return false
            const not_selected = this.options.find(option => {
                if(this.value.indexOf(option.id) >= 0) return false
                const suboptions = option.sub_levels ? option.sub_levels : [option]
                return suboptions.find(suboption => {
                    return this.value.indexOf(suboption.id) < 0
                })
            })
            return !not_selected
        },
        max_reached () {
            return !!this.maxSelection && (this.flattened_selection.length >= this.maxSelection)
        }
    },
    props: {
        label: String,
        value: {
            type: Array,
            default () {
                return []
            }
        },
        options: Array,
        scrollHeight: {
            type: String,
            default: "200px"
        },
        placeholder: String,
        disabled: Boolean,
        display: {
            type: String,
            validator: prop => !!prop && prop.match(/^(normal|chips)$/),
            default: "normal"
        },
        compactSelection: {
            default: false
        },
        minSelection: {
            type: Number,
            default: 0
        },
        maxSelection: {
            type: Number,
            default: 0
        },
        canToggleAll: {
            type: Boolean,
            default: true
        },
        closeOnToggle: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            focused: false,
            picking: false,
            highlighted_option: null,
            type_ahead: "",
            expanded: {}
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-multiselect {
    & > div.form-input {
        display: inline-flex;
        height: auto;
        cursor: pointer;
        position: relative;
        user-select: none;

        & > input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }
        & > label {
            overflow: hidden;
            flex: 1 1 auto;
            cursor: pointer;
            background: $light-color;
        }
        & > .nibnut-multiselect-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            background: $light-color;
        }

        &.focused {
            @include control-shadow();
            border-color: $primary-color;
        }
    }
    & > .popover {
        display: block;

        & > .popover-container {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 0;
            transform: none;

            .card {
                height: 0;
                .card-body {
                    padding-top: 0;
                    padding-left: 0;
                    padding-right: 0;
                    overflow: auto;

                    .menu {
                        margin: 0;
                        padding: 0;
                        border: 0;
                        box-shadow: none;

                        li {
                            margin: 0;

                            label {
                                padding: $unit-1 $unit-10;
                                margin: 0;

                                &.form-checkbox .form-icon {
                                    left: $unit-4;
                                    top: $unit-2;
                                }
                            }

                            &.active div {
                                background: $secondary-color;
                                color: $primary-color;
                            }
                        }
                    }
                    & > .menu {
                        & > li {
                            & + li {
                                margin-bottom: $unit-2;
                            }

                            & > .menu {
                                transform: none;

                                li {
                                    padding-left: $unit-6;
                                }
                            }
                        }
                    }
                }
                .card-footer:last-child {
                    padding: $unit-2;
                }
            }
        }
        &.active {
            & > .popover-container {
                transform: none;
                .card {
                    height: auto;
                }
            }
        }
    }
}
</style>
