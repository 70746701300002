<template>
    <span>
        {{ standardized_size | nibnut.number(number_format) }}
        <span v-if="standardized_units === constants('units', 'UNIT_SQFT').id">ft<sup>2</sup></span>
        <span v-else-if="standardized_units === constants('units', 'UNIT_ACRES').id">acres</span>
        <span v-else-if="standardized_units === constants('units', 'UNIT_HECTARES').id">hectares</span>
        <span v-else>m<sup>2</sup></span>
    </span>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

const ONE_ACRE_IN_SQ_FT = 43560
const SIZE_CUT_OFF_IN_ACRES = 0.5
const SIZE_CUT_OFF_IN_SQ_FT = ONE_ACRE_IN_SQ_FT * SIZE_CUT_OFF_IN_ACRES

export default {
    name: "ListingSizeDisplay",
    mixins: [misc_utilities],
    computed: {
        is_converted_value () {
            // if value is in sq ft and > 1 acre
            return this.autoConvert &&
                (
                    (
                        (this.units === this.constants("units", "UNIT_SQFT").id) &&
                        ((this.size / 100) >= SIZE_CUT_OFF_IN_SQ_FT)
                    ) ||
                    (
                        (this.units === this.constants("units", "UNIT_ACRES").id) &&
                        ((this.size / 100) < SIZE_CUT_OFF_IN_ACRES)
                    )
                )
        },
        standardized_size () {
            if(this.is_converted_value) {
                if(this.units === this.constants("units", "UNIT_SQFT").id) return (this.size / 100) / ONE_ACRE_IN_SQ_FT
                if(this.units === this.constants("units", "UNIT_ACRES").id) return (this.size / 100) * ONE_ACRE_IN_SQ_FT
            }

            return (this.size / 100)
        },
        standardized_units () {
            if(this.is_converted_value) {
                if(this.units === this.constants("units", "UNIT_SQFT").id) return this.constants("units", "UNIT_ACRES").id
                if(this.units === this.constants("units", "UNIT_ACRES").id) return this.constants("units", "UNIT_SQFT").id
            }
            return this.units
        },
        number_format () {
            if(this.standardized_units === this.constants("units", "UNIT_SQFT").id) return "0,0"
            return "0,0.0"
        }
    },
    props: {
        size: {
            type: Number,
            required: true
        },
        units: {
            type: Number,
            required: true
        },
        autoConvert: {
            type: Boolean,
            default: false
        }
    }
}
</script>
