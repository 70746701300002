<template>
    <span>
        <span v-if="!changed"><slot name="hint"></slot></span>
        <span v-else-if="!!value" class="mls-hint">
            {{ translate("MLS") }}: {{ value }}
            <default-button
                flavor="link"
                size="sm"
                :title="translate('Reset value to MLS value')"
                @click.prevent="$emit('click', value)"
            >
                <open-icon glyph="undo" size="1x" />
            </default-button>
        </span>
    </span>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "MlsHint",
    components: {
        DefaultButton,
        OpenIcon
    },
    props: {
        changed: {
            type: Boolean,
            required: true
        },
        value: {
            required: true
        }
    }
}
</script>

<style lang="scss">
.mls-hint {
    .btn.btn-sm {
        font-size: 0.6rem;
        height: 0.8rem;
        padding: 0 0.3rem;
        line-height: 1rem;

        & > span {
            display: inline-block;
            position: relative;
            top: -4px;
            line-height: 1em;
            height: 1em;
        }
    }
}
</style>
