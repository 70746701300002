<template>
    <modal-dialog
        id="property-notes-dialog"
        :show.sync="shown"
        :dismissable="true"
        class="modal-lg"
    >
        <template v-slot:title>
            <slot name="title">
                <span class="h5">{{ translate("Property Notes") }}</span>
            </slot>
            <default-button
                flavor="link"
                @click.prevent.stop="new_note"
            >
                <open-icon
                    glyph="plus-circle"
                />
            </default-button>
        </template>
        <template v-slot:subtitle><slot name="subtitle"></slot> </template>

        <table class="table table-striped notepad">
            <tbody>
                <tr
                    v-if="new_record"
                >
                    <td>
                        <form-textbox
                            id="content"
                            name="content"
                            v-model="new_record.content"
                            :required="false"
                            :show-on-ready="true"
                        >
                            <template v-slot:label>
                                <h6>{{ translate("New Note") }}</h6>
                            </template>
                        </form-textbox>
                        <div class="columns mt-2">
                            <div class="column">
                                <default-button
                                    :block="true"
                                    :disabled="creating"
                                    @click.prevent.stop="new_record = null"
                                >
                                    {{ translate("Cancel") }}
                                </default-button>
                            </div>
                            <div class="column">
                                <default-button
                                    color="primary"
                                    :block="true"
                                    :waiting="creating"
                                    :disabled="creating"
                                    @click.prevent.stop="create_note"
                                >
                                    {{ translate("Save") }}
                                </default-button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr
                    v-for="row in filtered_rows"
                    :key="row.id"
                >
                    <td>
                        <form-textbox
                            :id="`content=${row.id}`"
                            name="content"
                            v-model="row.content"
                            :editable="is_editable_row(row) && is_editing_row(row)"
                            :required="false"
                            :show-on-ready="true"
                            @input="save_row(row, ...arguments)"
                        >
                            <template v-slot:label>
                                <h6>
                                    {{ row.created_at | nibnut.date }}&nbsp;@&nbsp;{{ row.created_at | nibnut.date("HH:mm") }}&nbsp;&bull;&nbsp;{{ row.computed_author_name }}
                                    <default-button
                                        v-if="is_editable_row(row)"
                                        flavor="link"
                                        class="ml-2"
                                        @click.prevent.stop="toggle_row_editor(row)"
                                    >
                                        <open-icon
                                            :glyph="is_editing_row(row) ? 'times' : 'pencil-alt'"
                                        />
                                    </default-button>
                                    <div v-if="is_deleting_row(row)" class="d-flex float-right">
                                       <label class="form-label text-error flex-static">{{ translate("Really?") }}</label>
                                        <default-button
                                            flavor="link"
                                            color="success"
                                            class="flex-static"
                                            @click.prevent.stop="delete_note(row, false)"
                                        >
                                            {{ translate("No") }}
                                        </default-button>
                                        <default-button
                                            flavor="link"
                                            color="error"
                                            class="flex-static"
                                            @click.prevent.stop="delete_note(row, true)"
                                        >
                                            {{ translate("Yes", true) }}
                                        </default-button>
                                    </div>
                                    <default-button
                                        v-else-if="is_deletable_row(row)"
                                        flavor="link"
                                        color="error"
                                        class="float-right"
                                        @click.prevent.stop="delete_note(row)"
                                    >
                                        <open-icon
                                            glyph="trash"
                                        />
                                    </default-button>
                                </h6>
                            </template>
                        </form-textbox>
                    </td>
                </tr>
            </tbody>
        </table>
    </modal-dialog>
</template>

<script type="text/javascript">
import { is_remote_data_table_source, handles_saving } from "@/nibnut/mixins"

import {
    ModalDialog,
    DefaultButton,
    OpenIcon,
    FormTextbox
} from "@/nibnut/components"

export default {
    name: "NotePad",
    mixins: [is_remote_data_table_source, handles_saving],
    components: {
        ModalDialog,
        DefaultButton,
        OpenIcon,
        FormTextbox
    },
    watch: {
        topicEntity: "refresh",
        topicEntityId: "refresh",
        show: "refresh_ui"
    },
    methods: {
        refresh_ui () {
            this.deleting_rows = []
            this.edited_rows = []
            this.new_record = null
            this.creating = false
        },
        pre_load () {
            if((this.topicEntity !== this.state.filter_on) || (this.topicEntityId !== this.state.filter)) {
                this.set_state_values({
                    filter_on: this.topicEntity,
                    filter: this.topicEntityId
                })
            }
        },
        post_load () {
            if(!this.state.total) this.new_note()
            this.$emit("loaded", this.state.found)
        },
        is_editable_row (row) {
            return row && (!row.id || (row.author_id === this.profile.id) || this.is_at_least_administrator)
        },
        is_editing_row (row) {
            return this.edited_rows.indexOf(row.id) >= 0
        },
        toggle_row_editor (row) {
            const index = this.edited_rows.indexOf(row.id)
            if(index >= 0) this.edited_rows.splice(index, 1)
            else this.edited_rows.push(row.id)
        },
        new_note () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                this.new_record = record
                this.new_record.entity = this.topicEntity
                this.new_record.topic_id = this.topicEntityId
                if(this.$refs.editor && this.$refs.editor.$el) {
                    setTimeout(() => {
                        if(this.$refs.editor && this.$refs.editor.$el) {
                            const node = this.$refs.editor.$el.querySelector("textarea")
                            if(node) {
                                node.focus()
                                node.select()
                            }
                        }
                    }, 200)
                }
            }).catch(this.receive_error)
        },
        save_row (row, value, field) {
            if(row && !!row.id) this.save_field_for_record_id(this.entity, row.id, value, field)
        },
        create_note () {
            if(this.new_record) {
                this.creating = true
                const data = { ...this.new_record }
                this.$store.dispatch("CREATE_RECORD", {
                    entity: this.entity,
                    data
                }).then(() => {
                    this.new_record = null
                    this.refresh()
                }).catch(this.receive_error).then(() => {
                    this.creating = false
                })
            }
        },
        is_deletable_row (row) {
            return row && row.id && ((row.author_id === this.profile.id) || this.is_at_least_administrator)
        },
        is_deleting_row (row) {
            return this.deleting_rows.indexOf(row.id) >= 0
        },
        delete_note (row, proceed = null) {
            if(row && !!row.id) {
                if(proceed === null) this.deleting_rows.push(row.id)
                else {
                    const index = this.deleting_rows.indexOf(row.id)
                    if(index >= 0) {
                        if(!proceed) this.deleting_rows.splice(index, 1)
                        else {
                            this.$store.dispatch(
                                "RECORD_DELETE",
                                {
                                    entity: this.entity,
                                    id: row.id
                                }
                            ).then(() => {
                                this.deleting_rows.splice(index, 1)
                                this.refresh()
                            }).catch(this.receive_error)
                        }
                    }
                }
            }
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        state_identifier () {
            return `notepad-${this.topicEntity}-${this.topicEntityId}`
        },
        can_refresh () {
            return !!this.profile_id && !!this.topicEntity && !!this.topicEntityId
        },
        fields () {
            return ["fieldset::default"]
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        topicEntity: {
            type: String,
            required: true
        },
        topicEntityId: {
            type: Number,
            required: true
        }
    },
    data () {
        return {
            entity: "note",
            columns: {
                created_at: { label: "Date", sort: "desc", type: "amount" }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "created_at",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            deleting_rows: [],
            edited_rows: [],
            new_record: null,
            creating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#property-notes-dialog.modal.modal-lg .modal-container {
    td {
        padding-top: 0;
        padding-bottom: 0;
    }
    h6 {
        margin-bottom: 0;
    }
}
</style>
