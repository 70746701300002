<template>
    <div>
        <form-token-input
            v-if="hiddenFields.indexOf('keywords') < 0"
            :id="identifier_for('keywords')"
            name="keywords"
            :value="value.keywords"
            :required="false"
            :separators="[',','Enter']"
            @input="add_keyword"
            @delete="remove_keyword"
        >
            <template v-slot:label>
                <span v-if="defaultsMode">{{ translate("Keywords") }}</span>
                <span v-else>{{ translate("Keywords Pool") }}</span>
            </template>
        </form-token-input>
        <div v-if="(hiddenFields.indexOf('description') < 0)">
            <form-textbox
                v-if="defaultsMode"
                :id="identifier_for('description')"
                name="description"
                :value="value.description"
                size="lg"
                :required="false"
                @input="save"
            >
                <template v-slot:label>{{ translate("Description") }}</template>
                <template v-slot:hint>{{ translate("Dynamic Tokens: [realtor], [area]") }}</template>
            </form-textbox>
            <div v-else>
                <label class="form-label pb-0">{{ translate("Descriptions Pool") }}</label>
                <label class="form-label text-gray text-small pt-0">{{ translate("Dynamic Tokens: [realtor], [area]") }}</label>
                <table class="table">
                    <tbody>
                        <tr
                            v-for="(description, index) in value.description"
                            :key="index"
                        >
                            <td>
                                <form-textbox
                                    :id="identifier_for(`description-${index}`)"
                                    name="description"
                                    :value="description"
                                    size="md"
                                    :required="true"
                                    @input="save_description(index, $event)"
                                >
                                    <template v-slot:hint>
                                        <div v-if="deleting_description_at === index" class="columns">
                                            <div class="column text-right">
                                                <label class="form-label label-sm text-error">{{ translate("Really?") }}</label>
                                            </div>
                                            <div class="column col-auto">
                                                <default-button
                                                    color="success"
                                                    size="sm"
                                                    :block="true"
                                                    @click.prevent.stop="delete_description_at(index, false)"
                                                >
                                                    {{ translate("No") }}
                                                </default-button>
                                            </div>
                                            <div class="column col-auto">
                                                <default-button
                                                    color="error"
                                                    size="sm"
                                                    :block="true"
                                                    @click.prevent.stop="delete_description_at(index, true)"
                                                >
                                                    {{ translate("Yes", true) }}
                                                </default-button>
                                            </div>
                                        </div>
                                        <default-button
                                            v-else
                                            flavor="link"
                                            color="error"
                                            :block="true"
                                            size="sm"
                                            @click.prevent.stop="deleting_description_at = index"
                                        >
                                            {{ translate("Delete") }}
                                        </default-button>
                                    </template>
                                </form-textbox>
                            </td>
                        </tr>
                        <tr v-if="adding_description">
                            <td>
                                <form-textbox
                                    :id="identifier_for('description-new')"
                                    name="description"
                                    :value="new_description"
                                    size="md"
                                    :required="true"
                                    @input="save_description(-1, $event)"
                                >
                                    <template v-slot:hint>
                                        ZZZZZ
                                        <div class="columns">
                                            <div class="column col-6">
                                                <default-button
                                                    color="error"
                                                    :block="true"
                                                    size="sm"
                                                    @click.prevent.stop="adding_description = false"
                                                >
                                                    {{ translate("Cancel") }}
                                                </default-button>
                                            </div>
                                            <div class="column col-6">
                                                <default-button
                                                    color="success"
                                                    :block="true"
                                                    size="sm"
                                                    @click.prevent.stop="create_description"
                                                >
                                                    {{ translate("Add", true) }}
                                                </default-button>
                                            </div>
                                        </div>
                                    </template>
                                </form-textbox>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <default-button
                                    :block="true"
                                    size="sm"
                                    @click.prevent.stop="add_description"
                                >
                                    {{ translate("New Description...") }}
                                </default-button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
import FormTokenInput from "@/nibnut/components/Inputs/FormTokenInput"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "MetadataInput",
    components: {
        FormTokenInput,
        FormTextbox,
        DefaultButton
    },
    methods: {
        identifier_for (field) {
            return `${this.id}-${field}`
        },
        save (value, field) {
            const metadata = { ...this.value }
            metadata[field] = value
            this.$emit("input", metadata, "metadata")
        },
        add_keyword (keyword) {
            keyword = keyword.toLowerCase()
            const keywords = [...this.value.keywords]
            const index = keywords.indexOf(keyword)
            if(index < 0) keywords.push(keyword)
            this.save(keywords, "keywords")
        },
        remove_keyword (keyword) {
            const keywords = [...this.value.keywords]
            const index = keywords.indexOf(keyword.toLowerCase())
            if(index >= 0) keywords.splice(index, 1)
            this.save(keywords, "keywords")
        },
        save_description (index, value) {
            if(index < 0) this.new_description = value
            else {
                const descriptions = [...this.value.description]
                descriptions[index] = value
                this.save(descriptions, "description")
            }
        },
        delete_description_at (index, remove) {
            this.deleting_description_at = -1
            if(remove) {
                const descriptions = [...this.value.description]
                descriptions.splice(index, 1)
                this.save(descriptions, "description")
            }
        },
        add_description () {
            this.new_description = ""
            this.adding_description = true
            setTimeout(() => {
                const node = document.getElementById(this.identifier_for("description-new"))
                if(node) {
                    node.focus()
                    node.select()
                }
            }, 200)
        },
        create_description () {
            if(this.new_description) {
                const descriptions = [...this.value.description]
                descriptions.push(this.new_description)
                this.save(descriptions, "description")
            }
            this.adding_description = false
        }
    },
    computed: {
    },
    props: {
        id: {
            type: String,
            required: true
        },
        value: {
            required: true
        },
        defaultsMode: { // defaults-mode is when the whole value (keyword, description, ...) is used at once, VS the normal mode where *pieces* are picked at random
            type: Boolean,
            default: false
        },
        hiddenFields: {
            type: Array,
            default () {
                return []
            }
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            deleting_description_at: -1,
            editing_description_at: -1,
            new_description: "",
            adding_description: false
        }
    }
}
</script>
