<template>
    <form-textbox
        v-bind="properties"
        :value="standardized_value"
        v-on="listeners"
        @input="standardize_value"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint>
            <mls-hint
                :changed="changed"
                :value="mls_value(name)"
                @click="standardize_value($event, name)"
            >
                <slot name="hint"></slot>
            </mls-hint>
        </template>
    </form-textbox>
</template>

<script>
import is_mls_input from "./IsMlsInput"

import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"

export default {
    name: "FormMlsTextbox",
    mixins: [is_mls_input],
    components: {
        FormTextbox
    },
    props: {
        ...FormTextbox.props
    }
}
</script>
