<template>
    <div
        v-nibnut-on-scroll:global="scrolled"
    >
        <slot></slot>
        <div
            v-if="page < nbPages"
            class="text-center text-gray py-8"
        >
            {{ $root.translate("Loading...") }}
        </div>
    </div>
</template>

<script>
export default {
    name: "InfiniteList",
    watch: {
        page: "page_changed",
        version: "reset_current_page"
    },
    mounted () {
        this.page_changed()
    },
    methods: {
        page_changed () {
            if(this.page > this.current_page) this.current_page = this.page
        },
        reset_current_page () {
            this.current_page = 0
            this.watermark = 0
            this.page_changed()
        },
        scrolled () {
            if(this.current_page < this.nbPages) {
                const {
                    scrollTop,
                    scrollHeight,
                    clientHeight
                } = document.documentElement
                // SAFARI: clientHeight: 470 ; scrollHeight: 3168 ; scrollTop: 0 **** that's what does not work!
                // console.log("DEBUG", { page: this.page, current_page: this.current_page, watermark: this.watermark, load: (!this.loading && (this.page > this.watermark) && (((scrollTop || document.body.scrollTop) + clientHeight) >= (scrollHeight * 0.75))), scrollTop, scrollHeight, clientHeight, a: (scrollTop + clientHeight), b: (scrollHeight * 0.75) })
                /*
                console.log("DEBUG scrolled", { loading: this.loading })
                console.log("DEBUG page", { page: this.page })
                console.log("DEBUG watermark", { watermark: this.watermark })
                console.log("DEBUG page >= watermark", (this.page >= this.watermark))
                console.log("DEBUG 75% height", (((scrollTop || document.body.scrollTop) + clientHeight) >= (scrollHeight * this.sensitivity)))
                */
                if(!this.loading && (this.page >= this.watermark) && (((scrollTop || document.body.scrollTop) + clientHeight) >= (scrollHeight * this.sensitivity))) {
                    this.watermark = this.current_page + 1
                    // console.log("DEBUG move watermark", this.watermark)
                    this.$emit("next", this.watermark)
                }
            }
        }
    },
    props: {
        page: {
            type: Number,
            required: true
        },
        nbPages: {
            type: Number,
            required: true
        },
        sensitivity: {
            type: Number,
            default: 0.75
        },
        loading: {
            type: Boolean,
            default: false
        },
        version: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            current_page: 0,
            watermark: 0
        }
    }
}
</script>
