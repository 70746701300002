<template>
    <div class="btn-group">
        <default-button
            :active="listMode === DISPLAY_MODE_MAP"
            :size="size"
            @click="set_listMode(DISPLAY_MODE_MAP)"
        >
            <open-icon glyph="map-marked" />
        </default-button>
        <default-button
            :active="listMode === DISPLAY_MODE_CARDS"
            :size="size"
            @click="set_listMode(DISPLAY_MODE_CARDS)"
        >
            <open-icon glyph="border-all" />
        </default-button>
        <default-button
            :active="listMode === DISPLAY_MODE_LIST"
            :size="size"
            @click="set_listMode(DISPLAY_MODE_LIST)"
        >
            <open-icon glyph="bars" />
        </default-button>
    </div>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

import { DISPLAY_MODE_LIST, DISPLAY_MODE_CARDS, DISPLAY_MODE_MAP } from "@/custom/mixins/ListsListings"

export default {
    name: "ListModePicker",
    created () {
        this.DISPLAY_MODE_LIST = DISPLAY_MODE_LIST
        this.DISPLAY_MODE_CARDS = DISPLAY_MODE_CARDS
        this.DISPLAY_MODE_MAP = DISPLAY_MODE_MAP
    },
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        set_listMode (mode) {
            this.$emit("input", mode)
        }
    },
    props: {
        listMode: {
            type: String,
            required: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg)$/i),
            default: "md"
        }
    }
}
</script>
