<template>
    <div
        v-if="!!listing"
        id="listing-view-page"
        :class="{ scrolled: scrolled_deep }"
        class="hero-page"
        :style="{ 'scroll-margin-top': `${scroll_margin_top}px` }"
        v-nibnut-on-scroll="scrolled"
    >
        <div ref="hero" class="listing-view-hero-container">
            <slot name="hero">
                <listing-view-hero
                    :listing="listing"
                    :title="listing.computed_display_title || ''"
                    :active="true"
                />
            </slot>
            <base-link
                href="#"
                class="page-back"
                @click.prevent.stop="$router.back()"
            >
                <open-icon glyph="chevron-left" /> {{ translate("Back") }}
            </base-link>
        </div>
        <div class="hero-page-sections">
            <component
                v-for="section in sections"
                :key="section.id"
                :is="section.component"
                :id="section.id"
                :title="section.title"
                :listing="listing"
                :style="{ 'scroll-margin-top': `${scroll_margin_top}px` }"
            ></component>
        </div>
        <div
            id="page-toc"
            :class="{ active: toc_opened }"
            class="popover popover-left hover-disabled"
        >
            <default-button
                :color="scrolled_deep ? 'primary' : 'white'"
                :title="translate('Quick page navigation...')"
                size="lg"
                class="btn-action s-circle"
                @click.prevent="toc_opened = !toc_opened"
            >
                <open-icon glyph="ellipsis-v" size="lg" />
            </default-button>
            <div class="popover-container">
                <ul class="menu">
                    <li
                        class="menu-item"
                    >
                        <base-link
                            href="#seller-page-top"
                            @click.prevent.stop="scroll_to_hash('#seller-page-top')"
                        >
                            {{ translate("Top of page") }}
                        </base-link>
                    </li>
                    <li
                        v-for="section in sections"
                        :key="section.id"
                        class="menu-item"
                    >
                        <base-link
                            href="#section-summary"
                            @click.prevent.stop="scroll_to_hash(`#${section.id}`)"
                        >
                            {{ section.title }}
                        </base-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { string_utilities } from "@/nibnut/mixins"

import { BaseLink, DefaultButton, OpenIcon } from "@/nibnut/components"
import ListingViewHero from "./ListingViewHero"

export default {
    name: "ListingView",
    mixins: [string_utilities],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon,
        ListingViewHero
    },
    methods: {
        scrolled () {
            if(this.$refs && this.$refs.hero && this.$refs.hero) {
                this.scrolled_deep = window.scrollY >= (this.$refs.hero.clientHeight * 0.9)
            }
        },
        scroll_to_hash (hash) {
            const id = hash.replace(/#/, "")
            const node = document.getElementById(id)
            if(node) {
                const header = document.querySelector("header.navbar")
                this.scroll_margin_top = (header ? (header.clientHeight + 5) : 0) || 73
                this.scroll_to_id = id
                this.$nextTick(() => {
                    node.scrollIntoView({ block: "start", inline: "nearest", behavior: "smooth" })
                })
            }
            this.toc_opened = false
        }
    },
    computed: {
        title () {
            if(!!this.listing && !!this.listing.line1) return this.address_one_line(this.listing)
            return this.translate("Listing Editor")
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        },
        sections: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            toc_opened: false,
            scroll_margin_top: 73,
            scrolled_deep: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.hero-page {
    .hero-page-sections {
        padding: $unit-6;

        section + section {
            padding-top: $unit-6;
        }
    }
    .listing-view-hero-container {
        position: relative;

        a, a:hover, a:active, a:focus, a:visited {
            color: #fff;
        }
        .page-back {
            position: absolute;
            top: 1.2rem;
            left: 1.2rem;
            z-index: $zindex-1;
        }
    }
    #page-toc {
        position: fixed;
        top: $top-nav-height + $unit-6;
        right: $unit-6;
        z-index: $zindex-1;
    }
}
</style>
