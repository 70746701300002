<template>
    <section
        class="hero"
        :style="{ 'background-image': `url(${listing.computed_poster_url})` }"
    >
        <slot name="layer"></slot>
        <div class="hero-overlay">
            <div class="hero-container">
                <slot>
                    <h3>
                        {{ ((!!listing.sale_price ? listing.sale_price : listing.computed_price) / 100) | nibnut.listing_price(listing.price_frequency) }}
                        <span v-if="!!listing.sale_price" class="text-small"> ({{ translate("Asking Price") }}: {{ (listing.computed_price / 100) | nibnut.listing_price(listing.price_frequency) }})</span>
                    </h3>
                    <h1>{{ listing.computed_display_title }}</h1>
                    <div class="columns">
                        <h5 class="column no-wrap">
                            <span v-if="listing.days_on_market === 1">{{ translate("First Day On Market") }}</span>
                            <span v-else-if="listing.days_on_market">{{ listing.days_on_market | nibnut.number("0,0") }}&nbsp;{{ translate("Days On Market") }}</span>
                        </h5>
                        <h5 class="column no-wrap">
                            <open-icon glyph="bed" />&nbsp;{{ listing.computed_bedroom_count | nibnut.number("0,0") }}
                        </h5>
                        <h5 class="column no-wrap">
                            <open-icon glyph="bath" />&nbsp;{{ listing.computed_bathroom_count | nibnut.number("0,0") }}
                        </h5>
                        <h5 class="column no-wrap">
                            {{ listing.computed_size / 100 | nibnut.number("0,0") }}&nbsp;{{ translate("Sq.Ft.") }}
                        </h5>
                        <h5 class="column no-wrap">
                            {{ listing.computed_land_size / 100 | nibnut.number("0,0.0") }}&nbsp;{{ translate("Acres") }}
                        </h5>
                        <h5 class="column no-wrap">
                            {{ listing.construction_year }}
                        </h5>
                    </div>
                    <div class="columns">
                        <h5 class="column no-wrap">
                            {{ translate("Listed on") }}&nbsp;{{ listing.on_market_on | nibnut.date }}
                        </h5>
                        <h5 v-if="listing.accepted_on" class="column no-wrap">
                            {{ translate("Latest Offer on") }}&nbsp;{{ listing.accepted_on | nibnut.date }}
                        </h5>
                    </div>
                    <div v-if="pricing_history.length">
                        <div
                            v-for="action in pricing_history"
                            :key="action.id"
                            class="text-small"
                        >
                            {{ action.executed_on | nibnut.date }}:&nbsp;{{ (action.technical_description.new_price / 100) | nibnut.currency }}
                        </div>
                        <div class="text-small">
                            {{ translate("Listed at") }}&nbsp;{{ (pricing_history[(pricing_history.length - 1)].technical_description.old_price / 100) | nibnut.currency }}
                        </div>
                    </div>
                </slot>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { addl_misc_utilities } from "@/custom/mixins"

import { OpenIcon } from "@/nibnut/components"

export default {
    name: "ListingViewHero",
    mixins: [addl_misc_utilities],
    components: {
        OpenIcon
    },
    computed: {
        ...mapGetters(["entity_records"]),
        pricing_history () {
            const history = orderBy(
                this.entity_records("listing_action").filter(listing_action => {
                    const action = this.vocabulary("VOCABULARY_LISTING_ACTIONS").find(action => action.id === listing_action.action_id)
                    return action && (action.slug === "price-change")
                }),
                "executed_on",
                "desc"
            )
            if(history.length) history.shift()
            return history
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.hero {
    h5 + h5 {
        margin-left: $unit-3;
    }
}
</style>
