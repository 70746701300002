import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import MlsHint from "./MlsHint"

const is_mls_input = {
    mixins: [addl_misc_utilities],
    components: {
        MlsHint
    },
    methods: {
        mls_value (field) {
            return this.listing_mls_value(this.mls, field)
        },
        standardize_value (value, field) {
            // if(this.are_equal(value, this.mls_value(field))) value = null DO NOT standardize: that will be taken care of server-side
            this.$emit("input", value, field)
        }
    },
    computed: {
        properties () {
            const { mls, ...properties } = this.$props
            return properties
        },
        listeners () {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        changed () {
            return !this.are_equal(this.value, this.mls_value(this.name))
        },
        standardized_value () {
            if(this.value) return this.value || this.mls_value(this.name)
            return ""
        }
    },
    props: {
        mls: {
            type: Object,
            required: true
        }
    }
}

export default is_mls_input
