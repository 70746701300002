<template>
    <div v-if="active" class="master-search">
        <div class="input-group input-group-inline has-icon-right">
            <ul
                v-if="showing"
                class="menu"
            >
                <li
                    v-if="loading"
                    class="menu-item text-center"
                >
                    <loader size="xs" />
                </li>
                <li
                    v-else-if="!results.length"
                    :data-content="$root.translate('NO RESULTS')"
                    class="divider text-center"
                ></li>
                <li
                    v-for="(result, index) in results"
                    :key="`${result._entity}-${result.id}`"
                    :class="{ active: (active_choice_index === index) }"
                    class="menu-item clickable"
                    @click.prevent="handle_click(result)"
                >
                    <div
                        class="columns"
                    >
                        <div class="column col-2 col-md-3 col-sm-12">
                            <responsive-image
                                :src="result.computed_poster_url"
                                :alt="result._std_name"
                            />
                        </div>
                        <div class="column col-10 col-md-9 col-sm-12">
                            <span>{{ result._std_name }}</span>
                            <div class="text-gray text-small">
                                {{ translate("Property #{mls_id}", result) }}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <base-input
                id="master_search"
                ref="field"
                type="text"
                name="master_search"
                :value="query"
                :placeholder="$root.translate('Search by MLS #, address, or city')"
                :required="false"
                @focus="maybe_autoselect"
                @touchstart="touchstart"
                @keyup="keyup"
            />
            <open-icon
                glyph="times"
                @click.native="activate(false)"
                class="form-icon"
            />
        </div>
    </div>
    <default-button
        v-else
        flavor="link"
        :title="$root.translate('Press / to search')"
        v-shortkey="['/']"
        @click.prevent.stop="activate(true)"
        @shortkey="activate(true)"
    >
        <open-icon
            glyph="search"
            size="lg"
            class="form-icon"
        />
    </default-button>
</template>

<script>
import debounce from "lodash/debounce"

import { is_alpha_numerical_input } from "@/nibnut/mixins"

import { DefaultButton, BaseInput, ResponsiveImage, OpenIcon } from "@/nibnut/components"
import Loader from "@/custom/components/Loader"

export default {
    name: "MasterSearchBox",
    mixins: [is_alpha_numerical_input],
    components: {
        DefaultButton,
        BaseInput,
        ResponsiveImage,
        OpenIcon,
        Loader
    },
    mounted () {
        document.addEventListener("click", this.auto_close)
    },
    beforeDestroy () {
        document.removeEventListener("click", this.auto_close)
    },
    methods: {
        activate (active = null) {
            if((active === true) || ((active === null) && !this.active)) {
                this.active = true
                setTimeout(() => {
                    if(!!this.$refs.field && !!this.$refs.field.$el) this.$refs.field.$el.focus()
                }, 250)
            } else if((active === false) || ((active === null) && this.active)) {
                if(!!this.$refs.field && !!this.$refs.field.$el) this.$refs.field.$el.blur()
                this.active = false
                this.reset()
            }
        },
        maybe_autoselect (event) {
            event.target.select()
        },
        keyup (event) {
            if(this.alphanumeric && this.iOS) this.touching = false

            switch (event.key) {
            case "ArrowDown":
                if(this.showing && !!this.results.length) {
                    this.active_choice_index++
                    if(this.active_choice_index >= this.results.length) this.active_choice_index = 0
                }
                break

            case "ArrowUp":
                if(this.showing && !!this.results.length) {
                    this.active_choice_index--
                    if(this.active_choice_index < 0) this.active_choice_index = this.results.length - 1
                }
                break

            case "Enter":
                if(this.active_choice_index >= 0) {
                    const result = this.results[this.active_choice_index]
                    this.handle_click(result)
                    // this.$router.push({ name: `${result._entity}.edit`, params: { id: result.id } })
                    this.activate(false)
                }
                break

            case "Escape":
                this.activate(false)
                break

            default:
                this.active_choice_index = -1
                this.query = event.target.value
                this.search()
                break
            }
        },
        search: debounce(function () {
            if(this.showing) {
                this.loading = true
                this.$store.dispatch(
                    "RECORDS_ACTION",
                    {
                        entity: "app",
                        action: "search",
                        data: {
                            search: this.query,
                            fields: ["ns::listing;fieldset::global-search"]
                        },
                        method: "get",
                        passthru: true
                    }
                ).then(results => {
                    this.results = results
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            } else if(!!this.results && !!this.results.length) this.results = []
        }, 250),
        reset () {
            this.query = ""
            this.results = []
        },
        auto_close (event) {
            if(!!event && !!event.target && !this.$el.contains(event.target)) {
                this.activate(false)
            }
        },
        handle_click (result) {
            this.$store.dispatch("SET_GLOBAL_SEARCH_RESULT_SELECTION", { search_result: result })
            setTimeout(() => {
                this.reset()
            }, 200)
        }
    },
    computed: {
        showing () {
            return !!this.query && (this.query.length >= 3)
        }
    },
    data () {
        return {
            active: false,
            query: "",
            loading: false,
            results: [],
            active_choice_index: -1
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.master-search {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    padding: $layout-spacing-lg;
    z-index: $zindex-3;

    .menu {
        position: absolute;
        right: 0;
        top: 100%;
        min-width: 100%;
        max-height: 50vh;
        overflow: auto;

        .menu-item {
            white-space: nowrap;

            &.active {
                background: $secondary-color;
                color: $primary-color;
            }
        }
    }
}
</style>
