<template>
    <div
        :class="{ [size]: true, [color]: true }"
        class="ball-grid-pulse loader"
    >
        <div
            v-for="index in ball_count"
            :key="index"
        ></div>
    </div>
</template>

<script type="text/javascript">
/*
https://codepen.io/kjbrum/pen/BraCg
https://connoratherton.com/loaders
http://anastawfeek.github.io/ArtWorx-xLoader/
https://github.danielcardoso.net/load-awesome/animations.html
*/
export default {
    name: "Loader",
    computed: {
        ball_count () {
            if(this.size === "xs") return 3
            // if(this.size === "sm") return 4
            return 9
        }
    },
    props: {
        size: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(xs|sm|md|lg)$/),
            default: "md"
        },
        color: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(gray|light|primary)$/),
            default: "primary"
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";
@import "@/assets/sass/variables";
@import "~loaders.css/src/animations/ball-grid-pulse";

$base-ball-size: 0.8rem;
.loader {
    $ball-size: math.div($base-ball-size, 2);
    $margin: $unit-1;
    width: ($ball-size * 3) + $margin * 6;
    & > div {
        width: $ball-size;
        height: $ball-size;
        margin: $margin;
    }

    &.xs {
        $ball-size: math.div($base-ball-size, 4);
        $margin: math.div($unit-1, 2);
        width: ($ball-size * 3) + $margin * 6;
        white-space: nowrap;
        & > div {
            width: $ball-size;
            height: $ball-size;
            margin: $margin;
        }
    }
    &.sm {
        $ball-size: math.div($base-ball-size, 4);
        $margin: math.div($unit-1, 2);
        width: ($ball-size * 3) + $margin * 6;
        & > div {
            width: $ball-size;
            height: $ball-size;
            margin: $margin;
        }
    }
    &.lg {
        $ball-size: $base-ball-size;
        $margin: $unit-1 * 1.5;
        width: ($ball-size * 3) + $margin * 6;
        & > div {
            width: $ball-size;
            height: $ball-size;
            margin: $margin;
        }
    }

    &.light {
        & > div {
            background-color: $light-color;
        }
    }
    &.gray {
        & > div {
            background-color: $gray-color;
        }
    }
}
.text-center {
    .loader {
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
