<template>
    <div v-if="!!record" class="d-flex align-items-center">
        <div class="flex-static col-2 col-md-3 col-sm-4">
            <div
                class="nibnut-aspect-ratio-box nibnut-ratio-gallery"
            >
                <div>
                    <img
                        v-if="record.computed_poster_url"
                        :src="record.computed_poster_url"
                        :alt="record.computed_display_title"
                        loading="lazy"
                        decoding="async"
                        class="img-responsive"
                    />
                    <open-icon
                        v-else
                        glyph="camera"
                        size="2x"
                    />
                </div>
            </div>
        </div>
        <div class="flex-variable ml-4">
            <h6>
                {{  record.computed_display_title }}
            </h6>
            <h6 class="text-gray text-small">
                {{ translate("Property #") }}{{ record.id }}&nbsp;&bull;&nbsp;MLS&reg;&nbsp;#{{ record.mls_id }}
            </h6>
        </div>
    </div>
</template>

<script>
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ListingCompactListDisplay",
    components: {
        OpenIcon
    },
    props: {
        record: {
            type: Object
        }
    }
}
</script>
