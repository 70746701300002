<template>
    <form-input
        v-bind="properties"
        :value="standardized_value"
        v-on="listeners"
        @input="standardize_value"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value><slot name="empty-value">{{ standardized_value }}</slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint>
            <mls-hint
                :changed="changed"
                :value="mls_value(name)"
                @click="standardize_value($event, name)"
            >
                <slot name="hint"></slot>
            </mls-hint>
        </template>
    </form-input>
</template>

<script>
import is_mls_input from "./IsMlsInput"

import FormInput from "@/nibnut/components/Inputs/FormInput"

const typed_value = (value, type, default_value = null) => {
    if(type === "stripe") return (parseFloat(value) || default_value) * 100
    if(type === "float") return parseFloat(value) || default_value
    if(type === "number") return parseInt(value) || default_value
    return value
}
export default {
    name: "FormMlsInput",
    mixins: [is_mls_input],
    components: {
        FormInput
    },
    methods: {
        mls_value (field) {
            return typed_value(this.listing_mls_value(this.mls, field), this.type)
        }
    },
    props: {
        ...FormInput.props
    }
}
</script>
