<template>
    <div class="master-detail-container">
        <nav
            v-if="!!masterTitle || !!detailTitle"
            class="columns"
        >
            <div class="column">
                <default-button
                    v-if="!!masterTitle"
                    flavor="link"
                    @click.prevent="$emit('update:current-view', 'master')"
                >
                    <open-icon glyph="angle-left" class="mr-2" />
                    {{ masterTitle }}
                </default-button>
                <slot v-else name="left-view"></slot>
            </div>
            <div class="column text-right">
                <default-button
                    v-if="!!detailTitle"
                    flavor="link"
                    @click.prevent="$emit('update:current-view', 'detail')"
                >
                    {{ detailTitle }}
                    <open-icon glyph="angle-right" class="ml-2" />
                </default-button>
            </div>
        </nav>
        <div
            :class="{ [currentView]: true }"
        >
            <div
                v-if="has_slot('master') && (currentView === 'master')"
                class="master"
            >
                <slot name="master"></slot>
            </div>
            <div
                v-if="has_slot('detail') && (currentView === 'detail')"
                class="detail"
            >
                <slot name="detail"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "MasterDetail",
    mixins: [is_nibnut_component],
    components: {
        DefaultButton,
        OpenIcon
    },
    props: {
        currentView: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(master|detail)$/),
            required: true
        },
        masterTitle: {
            type: String,
            default: ""
        },
        detailTitle: {
            type: String,
            default: ""
        }
    }
}
</script>
