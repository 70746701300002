<template>
    <div class="listings-list">
        <div
            v-if="listMode === DISPLAY_MODE_MAP"
            class="listing-map"
        >
            <map-loader
                :id="id"
                :listings="pins"
                :anchored-to="anchoredTo"
                :map-config="map_config"
                :data-version="dataVersion"
                :selection="selection"
                @input="center = $event.center"
                @toggle-selection="$emit('select', $event)"
            />
        </div>
        <data-table
            v-else-if="listMode === DISPLAY_MODE_LIST"
            id="listings-list"
            entity-name="listing:::listings"
            :columns="columns"
            :rows="listings"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="canAdd"
            :selection="selection"
            :selection-id-field="selectionIdField"
            :searchable="false"
            :show-totals="canLoadMore"
            :empty-title="translate('No listings found')"
            small-screen-mq="^(xs|sm)$"
            @sort="sort_by"
            @add="record_create"
            @click="record_edit"
            v-on="listeners"
        >
            <template
                v-slot:summary="{ row }"
            >
                <a
                    href="#"
                    class="btn-gallery"
                    @click.prevent.stop="show_lightbox(row)"
                >
                    <div
                        class="nibnut-aspect-ratio-box nibnut-ratio-gallery"
                    >
                        <div>
                            <img
                                v-if="row.computed_poster_url"
                                :src="row.computed_poster_url"
                                :alt="address_one_line(row)"
                                loading="lazy"
                                decoding="async"
                                class="img-responsive"
                            />
                            <open-icon
                                v-else
                                glyph="camera"
                                size="2x"
                            />
                        </div>
                    </div>
                </a>
                <div class="my-2">
                    {{ row.computed_price / 100 | nibnut.listing_price("0,0", row.price_frequency) }}
                    <default-button
                        v-if="row.price_changes_list && row.price_changes_list.length"
                        flavor="link"
                        :data-tooltip="price_changes_list_for_row(row)"
                        size="sm"
                        class="tooltip"
                    >
                        <open-icon glyph="info-circle" size="lg" />
                    </default-button>
                    <div class="columns text-small">
                        <div v-if="!!row.computed_bedroom_count" class="column col-auto no-wrap">
                            <open-icon glyph="bed" />
                            {{ row.computed_bedroom_count | nibnut.number("0,0") }}
                        </div>
                        <div v-if="!!row.computed_bathroom_count" class="column col-auto no-wrap">
                            <open-icon glyph="bath" />
                            {{ row.computed_bathroom_count | nibnut.number("0,0") }}
                        </div>
                        <div v-if="!!row.size" class="column col-auto no-wrap">
                            <listing-size-display
                                :size="row.size"
                                :units="row.size_units"
                            />
                        </div>
                        <div v-if="!!row.land_size" class="column col-auto no-wrap">
                            <listing-size-display
                                :size="row.land_size"
                                :units="row.land_size_units"
                                :auto-convert="true"
                            />
                        </div>
                    </div>
                    <div
                        v-if="row.price_changes_list && row.price_changes_list.length"
                        class="text-small text-gray"
                    >
                        {{ translate("1 Price Change:::{n} Price Changes", { n: nibnut_filter("nibnut.number", [row.price_changes_list.length, "0,0"]) }, row.price_changes_list.length) }}
                    </div>
                </div>
                <h6>
                    {{ translate("1 day on market:::{n} days on market", { n: nibnut_filter("nibnut.number", [row.days_on_market, "0,0"]) }, row.days_on_market) }}
                </h6>
                <div
                    v-if="!!row.relisted_on"
                    class="text-small text-gray"
                >
                    {{ translate("Re-listed on {date}", { date: nibnut_filter("nibnut.date", [row.relisted_on]) }) }}
                </div>
                <div
                    v-if="(!!row.last_sent_at && is_at_least_realtor)"
                    class="text-small text-gray"
                >
                    {{ translate("Last sent on {date}", { date: nibnut_filter("nibnut.date", [row.last_sent_at]) }) }}
                </div>
                <h5>
                    {{  address_one_line(row) }}
                    <span class="text-gray ml-2">
                        <span v-if="!row.on_market_on">{{ translate("CMA") }}</span>
                        <span v-else-if="!!row.mls_id">#{{ row.mls_id }}</span>
                    </span>
                </h5>
                <div v-if="!!row.on_market_on && !!row.popularity">
                    <progress
                        :value="row.popularity / 100"
                        :max="100"
                        :class="{ 'progress-error': ((row.popularity / 100) <= 15), 'progress-warning': (((row.popularity / 100) > 15) && ((row.popularity / 100) <= 40)), 'progress-success': ((row.popularity / 100) > 40) }"
                        :data-tooltip="nibnut_filter('nibnut.number', [(row.popularity / 100), '0.0'])"
                        class="progress progress-lg tooltip"
                    ></progress>
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'info'">
                    <div class="columns">
                        <div v-if="!!row.computed_bedroom_count" class="column col-auto no-wrap">
                            <open-icon glyph="bed" />
                            {{ row.computed_bedroom_count | nibnut.number("0,0") }}
                        </div>
                        <div v-if="!!row.computed_bathroom_count" class="column col-auto no-wrap">
                            <open-icon glyph="bath" />
                            {{ row.computed_bathroom_count | nibnut.number("0,0") }}
                        </div>
                    </div>
                    <div class="columns">
                        <div v-if="!!row.size" class="column col-auto no-wrap">
                            <listing-size-display
                                :size="row.size"
                                :units="row.size_units"
                            />
                        </div>
                        <div v-if="!!row.land_size" class="column col-auto no-wrap">
                            <listing-size-display
                                :size="row.land_size"
                                :units="row.land_size_units"
                                :auto-convert="true"
                            />
                        </div>
                    </div>
                </div>
                <div v-if="field === 'mls_id'">
                    {{  address_one_line(row) }}
                    <span class="text-gray ml-2">
                        <span v-if="!row.on_market_on">{{ translate("CMA") }}</span>
                        <span v-else-if="!!row.mls_id">#{{ row.mls_id }}</span>
                    </span>
                    <div
                        v-if="!!row.relisted_on || (!!row.last_sent_at && is_at_least_realtor)"
                        class="text-small text-gray"
                    >
                        <span v-if="!!row.relisted_on">{{ translate("Re-listed on {date}", { date: nibnut_filter("nibnut.date", [row.relisted_on]) }) }}</span>
                        <span v-if="!!row.relisted_on && (!!row.last_sent_at && is_at_least_realtor)">&nbsp;&bull;&nbsp;</span>
                        <span v-if="(!!row.last_sent_at && is_at_least_realtor)">{{ translate("Last sent on {date}", { date: nibnut_filter("nibnut.date", [row.last_sent_at]) }) }}</span>
                    </div>
                </div>
                <div
                    v-else-if="field === 'gallery'"
                >
                    <span
                        v-if="row.pending_at || row.sold_on"
                        :class="{ mine: false }"
                        class="ribbon"
                    >
                        <span v-if="!!row.sold_on">{{ translate("Sold") }}</span>
                        <span v-else>{{ translate("Pending") }}</span>
                    </span>
                    <a
                        href="#"
                        class="btn-gallery"
                        @click.prevent.stop="show_lightbox(row)"
                    >
                        <div
                            class="nibnut-aspect-ratio-box nibnut-ratio-golden"
                        >
                            <div>
                                <img
                                    v-if="row.computed_poster_url"
                                    :src="row.computed_poster_url"
                                    :alt="address_one_line(row)"
                                    loading="lazy"
                                    decoding="async"
                                    class="img-responsive"
                                />
                                <open-icon
                                    v-else
                                    glyph="camera"
                                    size="2x"
                                />
                            </div>
                        </div>
                    </a>
                </div>
                <span v-else-if="field === 'days_on_market'">{{ translate("1 day:::{n} days", { n: nibnut_filter("nibnut.number", [row.days_on_market, "0,0"]) }, row.days_on_market) }}</span>
                <div v-else-if="field === 'computed_price'">
                    {{ (row.computed_price / 100) | nibnut.listing_price(row.price_frequency) }}
                    <default-button
                        v-if="row.price_changes_list && row.price_changes_list.length"
                        flavor="link"
                        :data-tooltip="price_changes_list_for_row(row)"
                        size="sm"
                        class="tooltip"
                    >
                        <open-icon glyph="info-circle" size="lg" />
                    </default-button>
                    <div
                        v-if="row.price_changes_list && row.price_changes_list.length"
                        class="text-small text-gray"
                    >
                        {{ translate("1 Price Change:::{n} Price Changes", { n: nibnut_filter("nibnut.number", [row.price_changes_list.length, "0,0"]) }, row.price_changes_list.length) }}
                    </div>
                </div>
                <div v-else-if="field === 'popularity'">
                    <progress
                        v-if="!!row.on_market_on && !!row.popularity"
                        :value="row.popularity / 100"
                        :max="100"
                        :class="{ 'progress-error': ((row.popularity / 100) <= 15), 'progress-warning': (((row.popularity / 100) > 15) && ((row.popularity / 100) <= 40)), 'progress-success': ((row.popularity / 100) > 40) }"
                        :data-tooltip="nibnut_filter('nibnut.number', [(row.popularity / 100), '0.0'])"
                        class="progress progress-lg tooltip"
                    ></progress>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
            <template v-slot:totals>
                <slot name="totals"></slot>
            </template>
        </data-table>
        <div v-else class="listing-cards">
            <div class="columns">
                <div class="column col-10 col-lg-9 col-md-8 col-sm-12 mb-4">
                    <label v-if="canLoadMore" class="form-label label-sm">
                        <slot name="totals">
                            <span v-if="state.found !== state.total">{{ translate("{number} listing found:::{number} listings found", { number: nibnut_filter("nibnut.number", [state.found, "0,0"]) }, state.found) }}&nbsp;/&nbsp;</span>{{ translate("{number} listing:::{number} listings", { number: nibnut_filter("nibnut.number", [state.total, "0,0"]) }, state.total) }}
                        </slot>
                    </label>
                </div>
                <div class="column col-2 col-lg-3 col-md-4 col-sm-12 mb-4">
                    <form-dropdown
                        id="listings-card-sorts"
                        name="sorts"
                        :value="current_menu_sort"
                        :options="menu_sorts"
                        size="sm"
                        :required="true"
                        @input="menu_sort"
                    />
                </div>
            </div>
            <infinite-list
                :loading="loading"
                :page="state.page || 1"
                :nb-pages="nb_pages || 1"
                :version="dataVersion"
                @next="$emit('page', state.page + 1)"
            >
                <div class="columns cards-list">
                    <div
                        v-for="row in listings"
                        :key="`listing-${row.id}`"
                        class="column col-3 col-lg-4 col-md-6 col-sm-12 mb-4"
                    >
                        <listing-card
                            :record="row"
                        >
                            <template v-slot:layer>
                                <div v-if="!!selection" class="card-selectors">
                                    <form-toggle-input
                                        :id="`selection-${row.id}`"
                                        name="selection"
                                        type="checkbox"
                                        :value="selection.indexOf(row.id) >= 0"
                                        :required="false"
                                        @click.native.stop
                                        @input="$emit('select', row.id)"
                                    />
                                </div>
                            </template>
                        </listing-card>
                    </div>
                </div>
            </infinite-list>
        </div>
        <listing-creation-dialog
            v-if="is_at_least_realtor"
            :show.sync="creating"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import crud_utilities from "@/nibnut/mixins/CrudUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"
import displays_listing from "@/custom/mixins/DisplaysListing"
import { lists_listings } from "@/custom/mixins/ListsListings"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import MapLoader from "@/nibnut/components/Map/MapLoader"
import DataTable from "@/nibnut/components/DataTable/DataTable"
import InfiniteList from "@/custom/components/InfiniteList"
import ListingCard from "@/custom/components/ListingCard"
import ListingSizeDisplay from "@/custom/components/ListingSizeDisplay"

export default {
    name: "ListingsList",
    mixins: [addl_profile_utilities, crud_utilities, string_utilities, displays_listing, lists_listings],
    components: {
        FormDropdown,
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        MapLoader,
        DataTable,
        InfiniteList,
        ListingCard,
        ListingSizeDisplay,
        ListingCreationDialog: () => import("@/custom/dialogs/ListingCreationDialog")
    },
    mounted () {
        this.recenter()
    },
    watch: {
        loading: "signal_loading",
        dataVersion: "recenter"
    },
    methods: {
        signal_loading () {
            this.$emit("loading", this.loading && !this.dataVersion)
        },
        refresh () {
            this.$emit("refresh")
        },
        recenter () {
            this.center = this.listings.length ? { lat: this.listings[0].latitude, lng: this.listings[0].longitude } : undefined
        },
        listing_images (listing) {
            const type = this.constants("attachment_types", "TYPE_IMAGE").id
            return orderBy(this.entity_records("attachment").filter(attachment => {
                return (attachment.owner_type === "App\\Listing") && (attachment.owner_id === listing.id) && (attachment.type === type)
            }), "sort_order", "asc")
        },
        show_lightbox (listing) {
            const display = (images) => {
                if(!!images && !!images.length) this.$store.dispatch("LIGHTBOX", { images: images })
            }
            const images = this.listing_images(listing)
            if(!!listing && !!listing.id && !!listing.computed_poster_url && !images.length) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "attachment",
                        query: {
                            listing_id: listing.id,
                            type: this.constants("attachment_types", "TYPE_IMAGE").id
                        }
                    }
                ).then(() => {
                    display(this.listing_images(listing))
                })
            } else display(images)
        },
        sort_by (field, sort) {
            const column = this.columns[field]
            if(!column || (column.sort === false)) return // false is not sortable

            Object.keys(this.columns).forEach(column_id => {
                if(column_id === field) this.columns[column_id].sort = sort || "asc"
                else if(this.columns[column_id].sort !== false) this.columns[column_id].sort = null
            })

            this.$emit("sort", field, column.sort)
        },
        menu_sort (id, field, option) {
            this.sort_by(option.field, option.sort)
        },
        record_edit (listing) {
            let id = listing.slug
            if(this.is_at_least_realtor_for_listing(listing)) id = listing.uuid
            this.$router.push({ name: "listing.edit", params: { id } })
        },
        record_create () {
            this.creating = true
        },
        price_changes_list_for_row (row) {
            return row.price_changes_list ? row.price_changes_list.join("\n") : ""
        }
    },
    computed: {
        listeners () {
            const listener_names = ["page", "select", "select-all-none"]
            const listeners = {}
            listener_names.forEach(listener => {
                if(this.$listeners[listener]) listeners[listener] = this.$listeners[listener]
            })
            return listeners
        },
        current_menu_sort () {
            return `${this.state.sort_by}.${this.state.sort_dir}`
        },
        menu_sorts () {
            const specs = {
                ...this.columns,
                days_on_market: { label: "Days on Market", sort: "asc", type: "numeric", filter_label: "List Date" }
            }
            const menu_sorts = []
            Object.keys(specs).filter(field => {
                return (specs[field].sort !== false) && !!specs[field].label.trim()
            }).forEach(field => {
                menu_sorts.push({ id: `${field}.asc`, field, sort: "asc", name: this.translate("{column} (low to high)", { column: specs[field].filter_label || specs[field].label }) })
                menu_sorts.push({ id: `${field}.desc`, field, sort: "desc", name: this.translate("{column} (high to low)", { column: specs[field].filter_label || specs[field].label }) })
            })
            return orderBy(menu_sorts, "name", "asc")
        },
        map_config () {
            const center = this.center || { lat: 48.9705506, lng: -123.9371303 }
            const zoom = this.center ? 12 : 10
            return {
                center,
                zoom
            }
        },
        nb_pages () {
            if(!this.state.per_page || !this.canLoadMore) return 1
            return Math.ceil(this.state.found / this.state.per_page)
        },
        pins () {
            const pins = [...this.listings]
            if(this.anchoredTo) pins.push(this.anchoredTo)
            return pins
        }
    },
    props: {
        id: {
            type: String
        },
        listMode: {
            type: String,
            required: true
        },
        listings: {
            type: Array,
            required: true
        },
        anchoredTo: {
            type: Object,
            default () {
                return {}
            }
        },
        state: {
            type: Object,
            required: true
        },

        selection: {
            type: Array,
            default () {
                return null
            }
        },
        selectionIdField: {
            type: String,
            default: "id"
        },

        dataVersion: {
            type: Number,
            default: 0
        },
        loading: {
            type: Boolean,
            default: false
        },
        canLoadMore: {
            type: Boolean,
            default: true
        },
        canAdd: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            columns: {
                gallery: { label: "Home", sort: false },
                mls_id: { label: "Listing", sort: "asc", type: "alpha", filter_label: "MLS #" },
                info: { label: "Details", sort: false },
                days_on_market: { label: "D.O.M.", sort: null, type: "numeric" },
                popularity: { label: "Popularity", sort: false, type: "numeric" },
                computed_price: { label: "Price", sort: null, type: "amount" }
            },
            center: undefined,
            creating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.listings-list {
    .btn-gallery {
        display: block;
        & > div > div {
            background-color: $bg-color;
            color: $gray-color-light;

            & > i.las {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    .listing-cards {
        .data-table-controls {
            padding: $unit-4;
            border: $border-width solid $border-color;

            .data-table-search-box {
                width: 100%;
            }
        }
        .card-selectors {
            position: absolute;
            top: $layout-spacing;
            left: $layout-spacing;
            display: flex;

            .btn + .form-group,
            .form-group + .btn {
                margin-left: $unit-2;
            }

            .btn.btn-sm {
                font-size: 0.8rem;
                padding: 0.05rem 0.25rem;
                border-color: transparent;
                &:focus,
                &:active,
                &.active {
                    border-color: transparent;
                }
                @include hover-supported {
                    border-color: transparent;
                }
            }
            .form-checkbox.input-md {
                margin: 0;
                height: 1.4rem;
                width: 1.4rem;
                line-height: 1.4rem;
                padding: 0;

                & > .form-icon {
                    height: 1.4rem;
                    width: 1.4rem;
                    top: 0;
                }
            }
        }
    }
    .data-table-controls {
        display: block;

        & > div:first-child {
            & > div.column:first-child {
                flex: 1 0 auto;
            }
            & > div.column:last-child {
                flex: 0 0 auto;
            }
        }
        @media(max-width:$size-sm) {
            .btn-group {
                display: flex;
            }
            & > div:first-child {
                & > div.column:first-child,
                & > div.column:last-child {
                    flex: 0 0 100%;
                }
            }
        }
    }
    .nibnut-data-table table.table td {
        position: relative;
        vertical-align: top;

        span.ribbon {
            &:after {
                display: none;
            }
            & > span {
                padding: 3px 0px;
                &:before, &:after {
                    display: none;
                }
            }
        }
    }
}
</style>
