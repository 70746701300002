<template>
    <div
        v-if="is_at_least_realtor_for_listing(listing) || !!state.found"
        class="listing-comparables"
    >
        <div class="columns">
            <div class="column col-sm-12">
                <h5 class="d-flex">
                    <label class="form-label flex-static">{{ translate("Comparables") }}</label>
                    <form-toggle-input
                        v-if="is_at_least_realtor_for_listing(listing) && !!state.found"
                        id="comparables_public"
                        name="comparables_public"
                        type="checkbox"
                        :value="listing.comparables_public"
                        :required="false"
                        class="flex-static ml-4"
                        @input="save"
                    >
                        {{ $root.translate('Show Seller') }}
                    </form-toggle-input>
                </h5>
            </div>
            <div v-if="!!state.found" class="column col-auto col-sm-12">
                <router-link
                    v-if="is_at_least_realtor_for_listing(listing)"
                    :to="{ name: 'listing.intelligence', params: {id: listing.id } }"
                    v-slot="{ href, navigate }"
                    custom
                >
                    <base-link
                        ref="link"
                        :href="href"
                        :title="translate('Setup Comparables...')"
                        class="btn btn-sm mr-4"
                        @click.stop="navigate"
                    >
                        <open-icon glyph="pencil-alt" />
                    </base-link>
                </router-link>
                <div
                    v-if="is_at_least_realtor_for_listing(listing)"
                    class="btn-group mr-4"
                >
                    <default-button
                        v-if="selection_mode"
                        :title="translate('Cancel')"
                        size="sm"
                        @click="toggle_selection_mode"
                    >
                        <open-icon glyph="times" />
                    </default-button>
                    <default-button
                        v-if="selection_mode"
                        color="primary"
                        :title="translate('Send {n} selected...', { n: selected_row_ids.length })"
                        size="sm"
                        @click="start_composing"
                    >
                        {{ translate("Send {n} selected...", { n: selected_row_ids.length }) }}
                    </default-button>
                    <default-button
                        v-else
                        :title="translate('Send to...')"
                        size="sm"
                        @click="toggle_selection_mode"
                    >
                        <open-icon glyph="share-square" />
                    </default-button>
                </div>
                <list-mode-picker
                    :list-mode="list_mode"
                    size="sm"
                    @input="set_list_mode"
                />
            </div>
        </div>

        <div v-if="loading && !data_version" class="text-center p-4">
            <loader />
        </div>
        <listings-list
            v-else-if="!!state.found"
            :list-mode="list_mode"
            :listings="rows"
            :anchored-to="{ id: listing.id, latitude: listing.latitude, longitude: listing.longitude }"
            :state="state"
            :data-version="data_version"
            :loading="loading"
            :selection="selection_mode ? selected_row_ids : null"
            @sort="sort_by"
            @page="goto_page"
            @select="select_row"
            @select-all-none="select_rows"
        >
            <template v-slot:totals>
                {{ translate("1 comparable listing:::{n} comparable listings", { n: state.found }, state.found) }}
            </template>
        </listings-list>
        <div v-else-if="is_at_least_realtor_for_listing(listing)" class="empty">
            <p class="empty-title h5">{{ translate("No Comparables Selected") }}</p>
            <p class="empty-subtitle">{{ translate("The seller will not see any comparables until you set up which ones they should see.") }}</p>
            <div class="empty-action">
                <router-link
                    :to="{ name: 'listing.intelligence', params: {id: listing.id } }"
                    v-slot="{ href, navigate }"
                    custom
                >
                    <base-link
                        ref="link"
                        :href="href"
                        class="btn btn-primary"
                        @click.stop="navigate"
                    >
                        {{ translate("Setup Comparables...") }}
                    </base-link>
                </router-link>
            </div>
        </div>
        <send-listings-dialog
            :show.sync="composing"
            :listing-ids="selection_mode ? selected_row_ids : []"
            :contacts="listing.contacts || []"
        />
    </div>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import can_send_listing_selection from "@/custom/mixins/CanSendListingSelection"
import { lists_listings } from "@/custom/mixins/ListsListings"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import ListingsList from "@/custom/components/ListingsList"
import Loader from "@/custom/components/Loader"

export default {
    name: "ListingComparables",
    mixins: [handles_saving, is_remote_data_table_source, can_send_listing_selection, lists_listings, addl_profile_utilities],
    components: {
        FormToggleInput,
        BaseLink,
        DefaultButton,
        OpenIcon,
        ListingsList,
        Loader
    },
    mounted () {
        this.reset()
    },
    watch: {
        "listing.id": "reset",
        "listing.comparables_query": "reset"
    },
    methods: {
        reset () {
            this.record_id = this.listing ? this.listing.id : 0
            return this.refresh()
        },
        pre_load () {
            this.set_state_values({
                filter: this.listing.id,
                advanced_query: {
                    ...this.listing.comparables_query
                }
            })
        },
        start_selecting (mode = "select") {
            this.selected_row_ids = []
            this.selection_mode = mode
        },
        stop_selecting () {
            this.selection_mode = false
            this.selected_row_ids = ""
        },
        toggle_selection_mode () {
            if(this.selection_mode) this.stop_selecting()
            else this.start_selecting()
        },
        save (value, field) {
            if(this.listing) {
                if(this.listing[field] !== value) this.listing[field] = value
                if(this.listing.id) return this.save_field(this.listing[field], field)
            }
            return Promise.resolve()
        }
    },
    computed: {
        state_identifier () {
            return "listing-comparables"
        },
        can_refresh () {
            return this.profile_id && this.listing && this.listing.id
        },
        fields () {
            return ["fieldset::list"]
        },
        list_mode_cookie_name () {
            return "derekgillette-comparables-mode"
        }
    },
    props: {
        listing: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "listing",
            default_state: {
                per_page: 150,
                page: 1,
                sort_by: "on_market_on",
                sort_dir: "desc",
                filter_on: "dashboard-comparables",
                filter: null,
                archived: false,
                search: "",
                advanced_query: {},
                total: 0,
                found: 0
            },
            apply_local_filter: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.listing-comparables {
}
</style>
