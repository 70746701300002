<template>
    <div class="inbox">
        <div class="card">
            <div class="card-header">
                <slot>
                    <h6>
                        <span v-if="state.found"> {{ translate("1 Message:::{n} Messages", { n: state.found }, state.found) }}</span>
                        <span v-else> {{ translate("Messages") }}</span>
                    </h6>
                </slot>
            </div>
            <div class="card-body">
                <div v-if="loading && !data_version" class="text-center p-4">
                    <loader />
                </div>
                <table v-else class="table">
                    <thead>
                        <tr>
                            <th>{{ translate("Date") }}</th>
                            <th>{{ translate("Subject") }}</th>
                            <th>{{ translate("From") }}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <inbox-message
                        v-for="row in filtered_rows"
                        :key="row.id"
                        :message="row"
                    />
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

import Loader from "@/custom/components/Loader"
import InboxMessage from "./InboxMessage"

export default {
    name: "Inbox",
    mixins: [is_remote_data_table_source],
    components: {
        Loader,
        InboxMessage
        // messageEditorDialog: () => import("@/custom/dialogs/messageEditorDialog")
    },
    watch: {
        listingId: "reset"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                per_page: this.perPage,
                listing_id: this.listingId
            })
        }
    },
    computed: {
        state_identifier () {
            return "messages"
        },
        can_refresh () {
            return !!this.profile_id
        },
        fields () {
            return ["fieldset::listing-editor"]
        }
    },
    props: {
        listingId: {
            type: Number,
            default: 0
        },
        perPage: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "listing_email",
            columns: {
                name: { label: "Subject", sort: false },
                received_at: { label: "Date", sort: "desc", type: "numeric" },
                from: { label: "From", sort: null, type: "alpha" }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "received_at",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                listing_id: 0
            }
        }
    }
}
</script>
