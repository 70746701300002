<template>
    <div class="columns listing-search-panel">
        <div class="column col-6 col-md-5 col-sm-12">
            <div class="columns">
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="min-price"
                        name="min_price"
                        :value="query.min_price"
                        type="stripe"
                        glyph="dollar-sign"
                        glyph-position="left"
                        :min="0"
                        :empty-value="null"
                        :placeholder="translate('No Minimum')"
                        :required="false"
                        @input="adjust_query"
                    >
                        <template v-slot:label>{{ translate("Price - Min") }}</template>
                    </form-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="max-price"
                        name="max_price"
                        :value="query.max_price"
                        type="stripe"
                        glyph="dollar-sign"
                        glyph-position="left"
                        :min="0"
                        :empty-value="null"
                        :placeholder="translate('No Maximum')"
                        :required="false"
                        @input="adjust_query"
                    >
                        <template v-slot:label>{{ translate("Price - Max") }}</template>
                    </form-input>
                </div>
            </div>
        </div>
        <div v-if="property_types" class="column col-6 col-md-6 col-sm-12">
            <form-multi-select
                v-if="property_types.length"
                id="types"
                name="types"
                :value="query.types"
                :options="property_types"
                :label="translate('Any Type')"
                display="chips"
                :required="false"
                @input="adjust_query"
            >
                <template v-slot:label>{{ translate('Property Type:::Property Types', {}, query.types ? query.types.length : 0) }}</template>
            </form-multi-select>
        </div>
        <!--
        <div class="column col-3 col-md-3 col-sm-12">
            <form-input
                id="age"
                name="age"
                :value="query.age"
                type="number"
                :min="0"
                :empty-value="null"
                :placeholder="translate('Any number of')"
                :required="false"
                @input="adjust_query"
            >
                <template v-slot:label>{{ translate("Age") }}</template>
                <template v-slot:right_addon>
                    <span class="input-group-addon">
                        {{ translate("year:::years", {}, query.age || 0) }}
                    </span>
                </template>
            </form-input>
        </div>
        //-->
        <div class="column col-6 col-md-12 col-sm-12">
            <div class="columns">
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="min_size"
                        name="min_size"
                        :value="query.min_size"
                        type="float"
                        :min="0"
                        :step="0.1"
                        :required="false"
                        :empty-value="null"
                        :placeholder="translate('No Minimum')"
                        @input="adjust_query"
                    >
                        <template v-slot:label>{{ translate("Size - Min") }}</template>
                        <template v-slot:right_addon>
                            <form-dropdown
                                id="size_units"
                                name="size_units"
                                :value="query.size_units"
                                :options="size_units"
                                :required="true"
                                @input="$emit('input', $event, 'size_units')"
                            />
                        </template>
                    </form-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="max_size"
                        name="max_size"
                        :value="query.max_size"
                        type="float"
                        :min="0"
                        :step="0.1"
                        :required="false"
                        :empty-value="null"
                        :placeholder="translate('No Maximum')"
                        @input="adjust_query"
                    >
                        <template v-slot:label>{{ translate("Size - Max") }}</template>
                        <template v-slot:right_addon>
                            <form-dropdown
                                id="size_units"
                                name="size_units"
                                :value="query.size_units"
                                :options="size_units"
                                :required="true"
                                @input="$emit('input', $event, 'size_units')"
                            />
                        </template>
                    </form-input>
                </div>
            </div>
        </div>
        <div class="column col-6 col-md-12 col-sm-12">
            <div class="columns">
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="min_land_size"
                        name="min_land_size"
                        :value="query.min_land_size"
                        type="float"
                        :min="0"
                        :step="0.1"
                        :required="false"
                        :empty-value="null"
                        :placeholder="translate('No Minimum')"
                        @input="adjust_query"
                    >
                        <template v-slot:label>{{ translate("Lot Size - Min") }}</template>
                        <template v-slot:right_addon>
                            <form-dropdown
                                id="land_size_units"
                                name="land_size_units"
                                v-model="query.land_size_units"
                                :options="land_size_units"
                                :required="true"
                                @input="$emit('input', $event, 'land_size_units')"
                            />
                        </template>
                    </form-input>
                </div>
                <div class="column col-6 col-sm-12">
                    <form-input
                        id="max_land_size"
                        name="max_land_size"
                        :value="query.max_land_size"
                        type="float"
                        :min="0"
                        :step="0.1"
                        :required="false"
                        :empty-value="null"
                        :placeholder="translate('No Maximum')"
                        @input="adjust_query"
                    >
                        <template v-slot:label>{{ translate("Lot Size - Max") }}</template>
                        <template v-slot:right_addon>
                            <form-dropdown
                                id="land_size_units"
                                name="land_size_units"
                                v-model="query.land_size_units"
                                :options="land_size_units"
                                :required="true"
                                @input="$emit('input', $event, 'land_size_units')"
                            />
                        </template>
                    </form-input>
                </div>
            </div>
        </div>
        <div v-if="neighborhoods" class="column col-6 col-md-12 col-sm-12">
            <form-multi-select
                v-if="neighborhoods.length"
                id="neighborhoods"
                name="neighborhoods"
                :value="query.neighborhoods"
                :options="neighborhoods"
                :label="translate('Any Area')"
                display="chips"
                :required="true"
                @input="adjust_query"
            >
                <template v-slot:label>{{ translate('Area:::Areas', {}, query.neighborhoods ? query.neighborhoods.length : 0) }}</template>
            </form-multi-select>
        </div>
        <div class="column col-6 col-md-12 col-sm-12 includes-sold">
            <div class="d-flex">
                <form-toggle-input
                    id="includes_sold"
                    name="includes_sold"
                    type="checkbox"
                    :value="!!query.or_sold_within"
                    :required="false"
                    class="flex-static"
                    @input="toggle_sales"
                >
                    {{ $root.translate('Include Sold Listings') }}
                </form-toggle-input>
                <label
                    v-if="!!query.or_sold_within"
                    :class="{ 'label-sm': $mq.match(/^(xs|sm)$/i) }"
                    class="form-label flex-static"
                >
                    {{ $root.translate('from the last') }}
                </label>
                <form-input
                    v-if="!!query.or_sold_within"
                    id="or_sold_within"
                    name="or_sold_within"
                    :value="query.or_sold_within"
                    type="number"
                    :min="1"
                    size="sm"
                    :required="false"
                    class="flex-static ml-2"
                    @input="adjust_query"
                >
                    <template v-slot:right_addon>
                        <span class="input-group-addon addon-sm">
                            {{ translate('days') }}
                        </span>
                    </template>
                </form-input>
            </div>
        </div>
        <div v-if="amenities" class="column col-12">
            <form-multi-select
                v-if="amenities.length"
                id="amenities"
                name="amenities"
                :value="query.amenities"
                :options="amenities"
                :label="translate('Any Amenity')"
                display="chips"
                :required="false"
                @input="adjust_query"
            >
                <template v-slot:label>{{ translate('Amenity:::Amenities', {}, query.amenities ? query.amenities.length : 0) }}</template>
            </form-multi-select>
        </div>
    </div>
</template>

<script>
import displays_listing from "@/custom/mixins/DisplaysListing"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormMultiSelect from "@/custom/components/FormMultiSelect"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"

export default {
    name: "ListingSearchPanel",
    mixins: [displays_listing],
    components: {
        FormInput,
        FormDropdown,
        FormMultiSelect,
        FormToggleInput
    },
    created () {
        this.load_default_vocabularies()
    },
    watch: {
        profile_id: "load_default_vocabularies"
    },
    methods: {
        toggle_sales (sold_within) {
            if(this.query) {
                if(sold_within) this.adjust_query(this.setting("comparables_sale_delay"), "or_sold_within")
                else this.adjust_query(0, "or_sold_within")
            }
        },
        adjust_query (value, name) {
            this.$emit("input", (value || null), name)
        }
    },
    computed: {
        includes_sold_delay () {
            if(this.query) return this.query.or_sold_within
            return 0
        }
    },
    props: {
        query: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.listing-search-panel {
    .includes-sold {
        input.form-input {
            width: 3rem;
        }
        & > .d-flex {
            margin-top: $unit-4;
            & > .form-group.flex-static:first-child,
            & > label.form-label {
                margin-top: $unit-5;
            }
            & > .form-group.flex-static:last-child {
                margin-top: $unit-6;
            }
        }
        @media (max-width: $size-md) {
            & > .d-flex {
                & > .form-group.flex-static:last-child {
                    & > .form-label {
                        display: none;
                    }
                }
            }
        }
        @media (max-width: $size-sm) {
            & > .d-flex {
                margin-top: $unit-2;
                flex-wrap: wrap;

                & > .form-group.flex-static:first-child,
                & > label.form-label,
                & > .form-group.flex-static:last-child {
                    margin-top: 0;
                }
                & > .form-group.flex-static:first-child {
                    flex: 1 0 100%;
                }
            }
        }
    }
}
</style>
