<template>
    <tbody class="inbox-message">
        <tr>
            <td>
                {{ message.received_at | nibnut.date }} @ {{ message.received_at | nibnut.date("HH:mm") }}
            </td>
            <td class="text-uppercase">
                {{ type_for_row(message).name }}
                <div
                    v-if="message.message"
                    class="text-small text-gray"
                >
                    <label v-if="!expanded">
                        {{ message.message | nibnut.truncate(30) }}
                        <default-button
                            flavor="link"
                            size="sm"
                            :class="{ active: expanded }"
                            class="btn-expand"
                            @click.prevent.stop="expanded = !expanded"
                        >
                            {{ translate("more") }}
                        </default-button>
                    </label>
                </div>
            </td>
            <td>
                <label class="form-label">
                    {{ message.from_name }}
                    <span v-if="message.telephone">&nbsp;&bull;&nbsp;</span>
                    <mailto-link
                        v-if="message.telephone"
                        :value="message.telephone"
                        filter="telto"
                    >
                        <open-icon glyph="phone" /> {{ message.telephone }}
                    </mailto-link>
                </label>
                <div
                    v-if="message.from_company || message.from_mailing_address"
                    class="text-small text-gray"
                >
                    <label class="form-label">
                        <span v-if="message.from_company">{{ message.from_company }}</span><span v-if="message.from_mailing_address">, {{ message.from_mailing_address }}</span>
                    </label>
                </div>
            </td>
            <td class="text-right">
                <base-link
                    v-if="!!message.reply_url"
                    :href="message.reply_url"
                    target="_blank"
                    class="btn btn-primary btn-block mt-2"
                >
                    <span v-if="(message.type === constants('email_types', 'TYPE_REQUEST').id) && message.confirmed">{{ translate("Confirmed") }}</span>
                    <span v-else-if="(message.type === constants('email_types', 'TYPE_REQUEST').id) && !message.confirmed">{{ translate("Confirm") }}<open-icon glyph="external-link-alt" class="ml-2" /></span>
                    <span v-else>{{ translate("Reply") }}<open-icon glyph="external-link-alt" class="ml-2" /></span>
                </base-link>
            </td>
        </tr>
        <tr v-if="expanded" class="inbox-message-body">
            <td colspan="4">
                {{ message.message }}
                <default-button
                    flavor="link"
                    size="sm"
                    :class="{ active: expanded }"
                    class="btn-expand"
                    @click.prevent.stop="expanded = !expanded"
                >
                    {{ translate("less") }}
                </default-button>
            </td>
        </tr>
    </tbody>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import MailtoLink from "@/nibnut/components/Links/MailtoLink"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "InboxMessage",
    mixins: [misc_utilities],
    components: {
        MailtoLink,
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
        type_for_row (row) {
            return Object.values(this.constants("email_types")).find(type => type.id === row.type) || { id: row.type, name: row.type }
        }
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            expanded: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

tbody.inbox-message {
    td {
        vertical-align: top;
    }
    label {
        padding: 0;
    }
    tr.inbox-message-body td {
        padding-left: $unit-4;
        padding-right: $unit-4;
        font-style: italic;
    }
    .btn.btn-expand {
        padding-top: 0;
        padding-bottom: 0;
        height: auto;
        line-height: normal;
        font-size: inherit;
        vertical-align: unset;

        i.las {
            transition: transform .3s ease;
        }
        &.active {
            i.las {
                transform: rotate(90deg);
            }
        }
    }
}
</style>
