<template>
    <div>
        <form-input
            v-bind="input_props"
            v-model="mls_id"
            :editable="can_be_set"
            :error="has_error(name)"
            :saving="saving(name)"
            v-on="input_listeners"
            @blur="check_and_redirect"
        >
            <template v-slot:read_only>
                <div class="d-flex">
                    <label class="form-label flex-variable">
                        {{ value }}
                    </label>
                    <!--
                    <div v-if="!editing && !!listingId && editable" class="flex-static">
                        <default-button
                            flavor="link"
                            :size="size"
                            :title="translate('Re-List...')"
                            class="ml-1"
                            @click.prevent="start_editing"
                        >
                            <open-icon glyph="redo-alt" />
                        </default-button>
                    </div>
                    //-->
                    <div v-if="!editing && !!value && !!listingId && editable" class="flex-static">
                        <default-button
                            flavor="link"
                            :size="size"
                            :waiting="syncing"
                            :title="translate('Synchronize MLS data...')"
                            :disabled="syncing || saving(name)"
                            class="ml-1"
                            @click.prevent="synchronize"
                        >
                            <open-icon glyph="sync" />
                        </default-button>
                    </div>
                </div>
            </template>
            <template v-slot:right_addon>
                <default-button
                    v-if="!!editing"
                    :size="size"
                    color="error"
                    class="input-group-btn"
                    @click.prevent="stop_editing"
                >
                    <open-icon glyph="times" />
                </default-button>
                <default-button
                    v-if="!!editing"
                    :size="size"
                    color="success"
                    class="input-group-btn"
                    @click.prevent="check_and_save"
                >
                    <open-icon glyph="check" />
                </default-button>
                <default-button
                    v-if="!editing && !!value && !!listingId && editable"
                    :size="size"
                    :waiting="syncing"
                    :disabled="syncing || saving(name)"
                    class="input-group-btn"
                    @click.prevent="synchronize"
                >
                    <open-icon glyph="sync" />
                </default-button>
            </template>
        </form-input>

        <modal-dialog
            id="mls-id-change-confirmation-dialog"
            :show.sync="confirming"
        >
            <template v-slot:title>
                <span class="h5">{{ $root.translate("Existing MLS Number") }}</span>
            </template>

            <div v-if="confirmation_data">
                <p>{{ $root.translate("MLS #{number} already exists: {title}, listed on {date}", { number: this.mls_id, title: confirmation_data.title, date: nibnut_filter("nibnut.date", [confirmation_data.on_market_on]) }) }}</p>
                <p v-if="!listingId">{{ $root.translate("Either use another MLS #, or modify the existing record.") }}</p>
                <p v-else>{{ $root.translate("Do you want to merge the two listings as a re-listing?") }}</p>
            </div>

            <template v-slot:footer>
                <div class="columns">
                    <div class="column">
                        <default-button
                            :block="true"
                            class="mx-2"
                            @click.prevent="stop_confirming"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                    </div>
                     <div v-if="listingId" class="column">
                        <default-button
                            color="primary"
                            :block="true"
                            class="mx-2"
                            @click.prevent="save_mls_id(true)"
                        >
                            {{ $root.translate("Re-Listing") }}
                        </default-button>
                    </div>
                    <div v-else-if="confirmation_data" class="column">
                        <router-link
                            :to="{ name: 'listing.edit', params: {id: confirmation_data.id } }"
                            v-slot="{ href, navigate }"
                            custom
                        >
                            <base-link
                                :href="href"
                                @click.stop="navigate"
                            >
                                {{ $root.translate("Modify Existing Record") }}
                            </base-link>
                        </router-link>
                    </div>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "FormMlsNumberInput",
    mixins: [handles_saving],
    components: {
        ModalDialog,
        FormInput,
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.reset_listing_id()
    },
    watch: {
        listingId: "reset_listing_id",
        value: "reset_mls_id"
    },
    methods: {
        reset_listing_id () {
            this.record_id = this.listingId
            this.syncing = false
            this.editing = false
            this.verifying = false
            this.confirming = false
            this.confirmation_data = null
            this.reset_mls_id()
        },
        reset_mls_id () {
            if(this.value !== this.mls_id) this.mls_id = this.value
        },
        start_editing () {
            this.reset_mls_id()
            this.editing = true
        },
        stop_editing () {
            this.editing = false
        },
        save_mls_id (relisted) {
            this.save_data_for_record_id(this.entity, this.record_id, {
                mls_id: this.mls_id,
                relisted,
                relation_ids: this.relationIds
            })
        },
        check_and_save () {
            if(this.listingId) {
                this.verifying = true
                this.$store.dispatch(
                    "RECORD_ACTION",
                    {
                        entity: "listing",
                        id: this.listingId,
                        action: "check-mls-id",
                        data: {
                            mls_id: this.mls_id
                        },
                        passthru: true
                    }
                ).then(response => {
                    if(!response.id) { // all good, we don't know that MLS ID yet...
                        this.stop_editing()
                        this.save(this.mls_id, "mls_id")
                    } else { // We already sync'ed or otherwise know of that MLS ID
                        this.confirmation_data = response
                        this.confirming = true
                        // confirm: cancel, or merge
                    }
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.verifying = false
                })
            }
        },
        check_and_redirect () {
            if(!this.listingId) { // new records only
                this.$store.dispatch(
                    "RECORD_ACTION",
                    {
                        entity: "listing",
                        id: this.listingId,
                        action: "check-mls-id",
                        data: {
                            mls_id: this.mls_id
                        },
                        passthru: true
                    }
                ).then(response => {
                    if(response.id) {
                        this.save(this.value, "mls_id")
                        this.confirmation_data = response
                        this.confirming = true
                    } else this.save(this.mls_id, "mls_id")
                })
            }
        },
        stop_confirming () {
            this.confirming = false
            this.confirmation_data = null
        },
        save (value, field) {
            if(this.record_id) {
                this.save_data_for_record_id(this.entity, this.record_id, {
                    [field]: value,
                    relation_ids: this.relationIds
                })
            }
            this.$emit("input", value, field)
        },
        synchronize () {
            this.syncing = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: this.entity,
                    id: this.record_id,
                    action: "sync",
                    data: {
                        relation_ids: this.relationIds
                    },
                    method: "post"
                }
            ).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.syncing = false
            })
        }
    },
    computed: {
        input_props () {
            const { listingId, relationIds, value, editable, disabled, ...input_props } = this.$props
            return input_props
        },
        input_listeners () {
            const { input, ...input_listeners } = this.$listeners
            return input_listeners
        },
        can_be_set () {
            return this.editable && (!this.listingId || this.editing)
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: ""
        },
        autocapitalize: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(none|sentences|words|characters)$/i),
            default: "sentences"
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(text|number|float|stripe|password|email|tel)$/i),
            default: "text"
        },
        glyph: {
            type: String,
            default: ""
        },
        glyphLibrary: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(las|lab)$/i),
            default: "las"
        },
        glyphPosition: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(left|right)$/i),
            default: "right"
        },
        glyphSize: {
            type: String,
            validator: prop => {
                return !prop || !!prop.match(/^(xs|1x|lg|2x|3x|4x|5x|6x|7x|8x|9x|10x)$/i)
            },
            default: "1x"
        },
        floatStep: {
            type: Number,
            default: 0.5
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        required: {
            type: Boolean,
            required: true
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        maxlength: {
            type: Number,
            default: null
        },
        emptyValue: {
            default: undefined
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        live: {
            type: Boolean,
            default: false
        },
        maybeAutofilled: {
            type: Boolean,
            default: false
        },
        listingId: {
            type: Number,
            required: true
        },
        relationIds: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            entity: "listing",
            syncing: false,
            mls_id: "",
            editing: false,
            verifying: false,
            confirming: false,
            confirmation_data: null
        }
    }
}
</script>
