<template>
    <div
        :class="{ expanded, flippable: (mode === 'comparable') }"
        class="card pull-up listing-card"
        @click.prevent.stop="proxy_click"
    >
        <base-link
            ref="image"
            href="#"
            class="card-image"
            @click.prevent.stop="display_gallery"
        >
            <responsive-image
                v-if="!!listing.computed_poster_url"
                :src="listing.computed_poster_url"
                :alt="title"
            />
            <image-placeholder
                v-else
                size="5x"
            />
        </base-link>
        <span
            v-if="!record.is_available"
            :class="{ mine: false }"
            class="ribbon"
        >
            <span v-if="!!record.sold_on">{{ translate("Sold") }}</span>
            <span v-else>{{ translate("Pending") }}</span>
        </span>
        <div class="card-header">
            <div class="card-title h5">
                <router-link
                    :to="{ name: 'listing.edit', params: {id: listing.id } }"
                    v-slot="{ href, navigate }"
                    custom
                >
                    <base-link
                        :href="href"
                        :target="clickTarget"
                        @click.stop="maybe_navigate($event, navigate)"
                    >
                        {{ (listing.computed_price / 100) | nibnut.listing_price(listing.price_frequency) }}
                    </base-link>
                </router-link>
            </div>
            <div class="card-title h5">
                <span>
                    <router-link
                        :to="{ name: 'listing.edit', params: {id: listing.id } }"
                        v-slot="{ href, navigate }"
                        custom
                    >
                        <base-link
                            ref="link"
                            :href="href"
                            :target="clickTarget"
                            @click.stop="maybe_navigate($event, navigate)"
                        >
                            <span v-html="title_line_1"></span>
                        </base-link>
                    </router-link>
                </span>
            </div>
            <div class="card-title h5">
                <router-link
                    :to="{ name: 'listing.edit', params: {id: listing.id } }"
                    v-slot="{ href, navigate }"
                    custom
                >
                    <base-link
                        :href="href"
                        :target="clickTarget"
                        @click.stop="maybe_navigate($event, navigate)"
                    >
                    <span v-html="title_line_2"></span>
                    </base-link>
                </router-link>
            </div>
            <div
                v-if="(!!listing.id || !!listing.mls_id)"
                class="card-subtitle text-small text-gray"
            >
                <span v-if="listing.id">
                    {{ translate("Listing #{id}", listing) }}
                </span>
                <span v-if="!!listing.id && !!listing.mls_id">&nbsp;&bull;&nbsp;</span>
                <span v-if="listing.mls_id">
                    {{ translate("Property #{mls_id}", listing) }}
                </span>
            </div>
        </div>
        <div
            v-if="mode !== 'compact'"
            class="card-body"
        >
            <slot>
                <div class="listing-info-1">
                    <div class="d-flex">
                        <div v-if="record.size" class="flex-variable">
                            <listing-size-display
                                v-if="listing.size"
                                :size="listing.size"
                                :units="listing.size_units"
                            />
                        </div>
                        <div v-if="record.land_size" class="flex-variable">
                            <listing-size-display
                                v-if="listing.land_size"
                                :size="listing.land_size"
                                :units="listing.land_size_units"
                                :auto-convert="true"
                            />
                        </div>
                        <div v-if="(!!record.computed_bathroom_count || !!record.computed_bedroom_count)" class="flex-static">
                            <span class="no-wrap">
                                <open-icon glyph="bath" />
                                {{ record.computed_bathroom_count | nibnut.number("0,0") }}
                            </span>
                            <span class="no-wrap ml-6">
                                <open-icon glyph="bed" />
                                {{ record.computed_bedroom_count | nibnut.number("0,0") }}
                            </span>
                        </div>
                    </div>
                    <div
                        class="columns text-small text-gray"
                    >
                        <div
                            v-for="descriptor in descriptors"
                            :key="descriptor.id"
                            class="column col-6 mt-2"
                        >
                            <span v-html="descriptor.label"></span>
                            <span
                                v-if="descriptor.id === 'price_changes_list'"
                                :data-tooltip="price_changes_list"
                                class="tooltip tooltip-left"
                            >
                                <open-icon glyph="info-circle" size="lg" />
                            </span>
                        </div>
                    </div>
                </div>
                <default-button
                    v-if="(mode === 'comparable')"
                    flavor="link"
                    size="sm"
                    :block="true"
                    @click.prevent.stop="toggle_expansion"
                >
                    <open-icon glyph="angle-down" />
                </default-button>
                <div v-if="(mode === 'comparable')" class="listing-info-2">
                    <div>
                        {{ listing_property_type(record) }}
                        <div class="columns mt-2">
                            <div v-if="record.construction_year" class="column">
                                {{ record.construction_year }}
                            </div>
                            <div
                                v-if="!!record.garage_spots"
                                :class="{ 'text-right': !!record.construction_year }"
                                class="column"
                            >
                                <open-icon glyph="car" />
                                {{ record.garage_spots | nibnut.number("0,0") }}
                            </div>
                        </div>
                    </div>
                    <div v-if="active_websites(listing).length" class="mt-2">
                        <div class="d-flex">
                            <div
                                v-for="website in active_websites(listing)"
                                :key="website.id"
                                class="website-tile flex-static mr-2"
                            >
                                <img :src="website.logo" :alt="website.name" loading="lazy" decoding="async" />
                            </div>
                        </div>
                    </div>
                    <router-link
                        :to="{ name: 'listing.edit', params: {id: listing.id } }"
                        v-slot="{ href, navigate }"
                        custom
                    >
                        <base-link
                            :href="href"
                            :target="clickTarget"
                            class="btn btn-primary btn-sm btn-block mt-2"
                            @click.prevent.stop="navigate"
                        >
                            {{ translate("View") }}
                        </base-link>
                    </router-link>
                </div>
            </slot>
        </div>
        <div
            v-if="mode === 'normal'"
            class="card-footer"
        >
            <slot name="footer">
                <div class="mt-4">
                    <div class="d-flex">
                        <div
                            v-for="website in active_websites(listing)"
                            :key="website.id"
                            class="website-tile flex-static mr-4"
                        >
                            <img :src="website.logo" :alt="website.name" loading="lazy" decoding="async" />
                        </div>
                    </div>
                </div>
            </slot>
        </div>
        <slot name="layer"></slot>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import string_utilities from "@/nibnut/mixins/StringUtilities"
import displays_listing from "@/custom/mixins/DisplaysListing"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import ResponsiveImage from "@/nibnut/components/Media/ResponsiveImage"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import ListingSizeDisplay from "@/custom/components/ListingSizeDisplay"
import ImagePlaceholder from "./ImagePlaceholder"

export default {
    name: "ListingCard",
    mixins: [string_utilities, displays_listing, addl_profile_utilities],
    components: {
        BaseLink,
        ResponsiveImage,
        DefaultButton,
        OpenIcon,
        ListingSizeDisplay,
        ImagePlaceholder
    },
    methods: {
        toggle_expansion () {
            setTimeout(() => {
                this.expanded = !this.expanded
            }, 200)
        },
        display_gallery () {
            const display = (images) => {
                if(!!images && !!images.length) this.$store.dispatch("LIGHTBOX", { images: images, start_at: 0, value: this.listing.computed_price })
            }
            const listing = this.listing
            const images = this.images
            if(!!listing && !!listing.id && !!listing.computed_poster_url && !images.length) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "attachment",
                        query: {
                            listing_id: listing.id,
                            type: this.constants("attachment_types", "TYPE_IMAGE").id
                        }
                    }
                ).then(() => {
                    display(this.images)
                })
            } else display(images)
        },
        proxy_click () {
            if(this.$refs.link) {
                this.$refs.link.$el.click()
            }
        },
        maybe_navigate (event, navigate) {
            if(!this.clickTarget) navigate(event)
        }
    },
    computed: {
        listing () {
            return this.edited_record || this.record
        },
        images () {
            const listing = this.listing
            if(!listing || !listing.id) return []
            const type = this.constants("attachment_types", "TYPE_IMAGE").id
            return orderBy(
                this.entity_records("attachment").filter(attachment => {
                    return (attachment.owner_type === "App\\Listing") && (attachment.owner_id === this.listing.id) && (attachment.type === type)
                }),
                "sort_order",
                "asc"
            )
        },
        rooms () {
            return this.entity_records("room").filter(room => room.listing_id === this.listing.id)
        },
        address_line_2 () {
            return this.string_concat(", ", this.listing.city, this.string_concat(" ", this.listing.state))
        },
        title_line_1 () {
            return this.address_block_line(1, this.listing) || this.address_line_2 || "&nbsp;"
        },
        title_line_2 () {
            const line_2 = this.address_line_2
            const line_1 = this.title_line_1
            if(line_1 === line_2) return "&nbsp;"
            return line_2 || "&nbsp;"
        },
        title () {
            const line_1 = this.address_block_line(1, this.listing) || this.address_line_2
            const line_2 = this.address_line_2
            if(line_1 === line_2) return line_1
            return `${line_1}, ${line_2}`
        },
        price_changes_list () {
            return (this.record && this.record.price_changes_list) ? this.record.price_changes_list.join("\n") : ""
        },
        descriptors () {
            const descriptors = []

            if(this.record.days_on_market) descriptors.push({ id: "days_on_market", label: this.translate("1 Day on market:::{n} Days on market", { n: this.nibnut_filter("nibnut.number", [this.record.days_on_market, "0,0"]) }, this.record.days_on_market) })
            if(!!this.record.price_changes_list && this.record.price_changes_list.length) descriptors.push({ id: "price_changes_list", label: this.translate("1 Price Change:::{n} Price Changes", { n: this.nibnut_filter("nibnut.number", [this.record.price_changes_list.length, "0,0"]) }, this.record.price_changes_list.length) })
            if(this.record.relisted_on) descriptors.push({ id: "relisted_on", label: this.translate("Re-listed on {date}", { date: this.nibnut_filter("nibnut.date", [this.record.relisted_on]) }) })
            if(!!this.record.last_sent_at && this.is_at_least_realtor) descriptors.push({ id: "last_sent_at", label: this.translate("Last sent on {date}", { date: this.nibnut_filter("nibnut.date", [this.record.last_sent_at]) }) })

            while(descriptors.length < 4) {
                descriptors.push({ id: descriptors.length, label: "&nbsp;" })
            }
            return descriptors
        }
    },
    props: {
        record: {
            type: Object,
            required: true
        },
        mode: {
            type: String,
            validator: prop => !!prop && prop.match(/^(normal|compact)$/),
            default: "normal"
        },
        clickTarget: {
            validator: prop => (prop === null) || (typeof prop === "string"),
            default: null
        }
    },
    data () {
        return {
            expanded: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.card.listing-card {
    border-radius: 0.2rem;
    cursor: pointer;
    &:not(.stick-to-top) {
        position: relative;
    }
    a:not(.btn) {
        $link-color: $body-font-color;
        $link-color-dark: darken($link-color, 10%);
        $link-color-light: lighten($link-color, 10%);
        color: $link-color;

        &:focus,
        &:active,
        &.active {
            color: $link-color-dark;
        }
        @include hover-supported {
            color: $link-color-dark;
        }

        &:visited {
            color: $link-color-light;
        }
    }
    .card-image {
        max-height: 170px;
        overflow: hidden;
        transition: max-height .3s ease;
        img {
            max-width: 100%;
            border-top-left-radius: 0.15rem;
            border-top-right-radius: 0.15rem;
        }
    }
    .card-header,
    .card-body,
    .card-footer {
        .form-group {
            display: inline-block;

            input[name="size"] {
                flex: 0 1 6em;
            }
        }
    }
    & > .card-body {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        transition: all .3s ease;

        & > .listing-info-1 {
            flex: 0 0 auto;
        }
        & > .listing-info-2 {
            flex: 0 1 0px;
            position: relative;
            height: auto;
            overflow: hidden;
            transition: flex .3s ease;

            i.las {
                font-size: 1rem;
            }

            .btn {
                position: absolute;
                bottom: 0.5em;
                left: 0;
                right: 0;
            }
        }
        & > .btn.btn-link {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0.3em;
            height: 16px;
            padding: 0;
            line-height: 0.6rem;
            i.las {
                font-size: 0.6rem;
                transition: transform .3s ease;
            }
        }
    }
    span.ribbon {
        top: -6.1px;
        right: 10px;

        &:after {
            border-left: 44px solid transparent;
            border-right: 44px solid transparent;
        }
        & > span {
            font-size: 14px;
            padding: 12px 8px 10px;
            border-top-right-radius: 8px;
            width: 90px;
        }
    }

    &.expanded {
        & > .card-image {
            max-height: 0px;
        }
        & > .card-body {
            height: 9.25rem;
            padding-bottom: $layout-spacing-lg;
            & > .listing-info-2 {
                flex: 1 0 auto;
            }
            & > .btn {
                i.las {
                    transform: rotate(180deg);
                }
            }
        }
    }
    &.flippable {
        & > .card-body {
            height: 4.5rem;
        }
    }
}
</style>
